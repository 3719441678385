
import React, { useEffect, useState } from 'react';

import profilepic from '../assets/img/01.webp';



export default function Procard2(tdata){
    

    const [ttdata, setData]= useState('');
    useEffect(() => {
        setData(tdata.tdata)
       }, [tdata])
       

function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

useEffect(() => {
    // console.log(ttdata);
}, [])




    return(
        <>
     
            <div className="card p-3 my-4">
            <h4 className='text-muted'>About Me</h4>
            <p className='doc-location'>{ttdata.About}</p>	
			</div> 
            <div className="card p-3 my-4">
            <h4 className='text-muted'>Basic Details</h4>
            <div className='d-flex mt-2'> 
            <div className='flex-1'>
            <p className='doc-location'>Profile created for : {ttdata.register}</p>
            <p className='doc-location'>weight : {ttdata.weight} kgs</p>
            <p className='doc-location'>Marital Status : {ttdata.martialStatus} </p>
            <p className='doc-location'>Name : {ttdata.name}</p>
            <p className='doc-location'>Location :  {ttdata.hometown} , {ttdata.nationality}</p>
            </div>
            <div className='flex-1'>
            <p className='doc-location'>martialStatus : {ttdata. martialStatus}</p>
            <p className='doc-location'>Age: {ttdata. dob}</p>
            <p className='doc-location'>Mother Tongue : {ttdata.  Langg}</p>
            <p className='doc-location'>DrinkingHabits : {ttdata. DrinkingHabits}</p>
            <p className='doc-location'>EatingHabits : {ttdata.EatingHabits}</p> 
            <p className='doc-location'>SmokingHabits : {ttdata. SmokingHabits}</p>
            </div>

            

            

           
           
            </div>
           	
			</div> 
            
            <div className="card p-3 my-4">
            <h4 className='text-muted'>Religion Information</h4>
            <div className='d-flex mt-2'> 
            <div className='flex-1'>
            <p className='doc-location'>Religion: {ttdata.religion}</p>
            <p className='doc-location'>Gothram: {ttdata.  Gothram} </p>
            <p className='doc-location'>Star/Raasi : {ttdata. star} ,{ttdata. zodiac}</p>
            <p className='doc-location'>Dosham: {ttdata.Dosham}</p>
            <p className='doc-location'>Caste/Subcaste :  {ttdata.caste} , {ttdata.  Subcaste}</p>
            </div>
            <div className='flex-1'>
            <p className='doc-location'>Time of Birth : {ttdata. TimeofBirth}</p>
            <p className='doc-location'>Place of Birth: {ttdata. PlaceofBirth}</p>
        
            </div>
            </div>
            </div>

            <div className="card p-3 my-4">
            <h4 className='text-muted'>Groom's Location</h4>
            <div className='d-flex mt-2'> 
            <div className='flex-1'>
            <p className='doc-location'>Country: {ttdata.Country}</p>
            <p className='doc-location'>State: {ttdata.  State} </p>
            <p className='doc-location'>Ancestral Origin : {ttdata. AncestralOrigin} </p>
            <p className='doc-location'>City: {ttdata.City}</p>
            <p className='doc-location'>Citizenship :  {ttdata.Citizenship} </p>
          </div>
            </div>
            </div>

            <div className="card p-3 my-4">
            <h4 className='text-muted'>Professional Information</h4>
            <div className='d-flex mt-2'> 
            <div className='flex-1'>
            <p className='doc-location'>Education: {ttdata.education}</p>
            <p className='doc-location'>Education In Details: {ttdata. EducationInDetails} </p>
            <p className='doc-location'>College/Institution : {ttdata. College}</p>
            <p className='doc-location'>Employed In: {ttdata.job}</p>
            <p className='doc-location'>Occupation:  {ttdata.Occupation} </p>
            </div>
            <div className='flex-1'>
            <p className='doc-location'> Organization: {ttdata. Organization}</p>
            <p className='doc-location'>Annual Income: {ttdata. AnnualIncome}</p>
            <p className='doc-location'>Occupation In Details:  {ttdata.OccupationInDetails} </p>
        
            </div>
            </div>
            </div>

            <div className="card p-3 my-4">
            <h4 className='text-muted'>Family Details</h4>
            <div className='d-flex mt-2'> 
            <div className='flex-1'>
            <p className='doc-location'>Family Values: {ttdata.FamilyValues}</p>
            <p className='doc-location'>Family Type: {ttdata. FamilyType} </p>
            <p className='doc-location'>Family Status: {ttdata. FamilyStatus}</p>
            <p className='doc-location'>No. of Siblings: {ttdata.siblings}</p>
            <p className='doc-location'> Father's Occupation:  {ttdata.Occupation} </p>
            </div>
            <div className='flex-1'>
            <p className='doc-location'>Mother's Organization: {ttdata. Organization}</p>
            <p className='doc-location'>Family Location: {ttdata. AnnualIncome}</p>
          
        
            </div>
            </div>
            </div>
            
            <div className="card p-3 my-4">
            <h4 className='text-muted'>About My Family</h4>
            <p className='doc-location'>{ttdata.About}</p>	
			</div> 
            
            <div className="card p-3 my-4">
            <h4 className='text-muted'>Hobbies and Interests</h4>
            <p className='doc-location'>{ttdata.About}</p>	
			</div> 
      
        </>
    );
} 