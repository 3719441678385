import React, { Component } from "react";
import PhoneInput from "react-phone-input-2";
import logo from "../assets/logo.png";
import img2 from "../assets/bg.png";
import { Link } from "react-router-dom";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';

export default function Regl({ hansubmit, statel,hanChan,hanChanp,  err,errl }) {

  function onkp(event) {
    // console.log(event.key);
    const selection = window.getSelection().toString();
    if (selection) {
      return true;
    }
    const nextValue = event.target.value;
    const maxLength = 10;
    if (nextValue.length >= maxLength) {
      event.preventDefault();
      return false;
    }
    if (event.key == 'e') {
      event.preventDefault();
      return false;
    }
    return true;
  }
  return (
    <div>
      

      <div className="body bg-cover" style={
       { backgroundImage: `url(${img2})`}
      
      }>
        <div className="regform">
          <h1 className="reg"> Register Now </h1>
       
          <FormControl fullWidth>
          <InputLabel variant="standard" htmlFor="uncontrolled-native">
          Register For 
        </InputLabel>
          <NativeSelect name="register" id="register"
          onChange={hanChan} value={statel.register} 
          >
            <option value="My-self">My-self</option>
            <option value="My-Daughter">My-Daughter</option>
            <option value="My-Son">My-Son</option>
            <option value="My-Friend">My-Friend</option>
            <option value="My-Relative">My-Relative</option>
          </NativeSelect>
          </FormControl>
        
          <FormControl fullWidth>
          <InputLabel variant="standard" htmlFor="uncontrolled-native">
          Gender
        </InputLabel>
          <NativeSelect  name="Gender" id="Gender"
          onChange={hanChan} value={statel.Gender} 
          >
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            </NativeSelect>
          </FormControl>
          <FormControl error sx={{ m: 1, width: '25ch' }} variant="standard"  >
           <InputLabel htmlFor="standard-adornment-name">Enter Name *</InputLabel>
          <Input
          
            id="standard-adornment-name"
            type= 'text' 
            onChange={hanChan}
          value={statel.name}
          name="name" 
          />
          {statel.acc.name == '' && <FormHelperText>{err}</FormHelperText>}
          </FormControl>
          <div className="phonee">
          <FormControl error sx={{ m: 1, width: '25ch' }} variant="standard"  >
           <InputLabel htmlFor="standard-adornment-phone">Mobile Number *</InputLabel>
           <Input
          
          id="standard-adornment-phone"
          type= 'number' 
       
          onChange={hanChanp}
        value={statel.phonee}
        name="phonee" 
        onKeyPress={onkp}
        />
          {/* <PhoneInput
            placeholder="Enter phone number"
            country="in"
            className="phonee"
            onChange={hanChanp} value={statel.phonee} name='phonee'
          /> */}
          <FormHelperText>{errl}</FormHelperText>
          </FormControl></div>
          <button className='matbut upp' onClick={hansubmit}>Register Free</button>
        </div>
       
      </div>
      <h4 className="footer tgg">
        {"  "}
        Copyright © 2023
        Tamizh Manamaalai{"   "}
        All rights reserved.
        {"   "}
        <br />{""}
         Design By{" "}

        <a href="http://skyhost.co.in/" className="link">
         
        Skyhost Technologies.
        </a>{" "}
      </h4>
    </div>
  );
}
