import React, { Component, useState } from "react";
import PhoneInput from "react-phone-input-2";
import logo from "../assets/logo.png";
// import {img1} from './assets/01.png';
import img2 from "../assets/bg.png";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import { TextField } from "@mui/material";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
// import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';


export default function Login() {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API,
    headers: {
        'content-type': 'application/json',
        // 'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
    }
});
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [log , SetLog]=useState({acc:{email:'',password:''}})
  const [err , SetError ]=useState('')
  const hanChange=(e)=>{
    const acc = {...log.acc};
    acc[e.target.name]=e.target.value;
    SetLog({
      acc:acc
    })
  }
  const navigate = useNavigate();
  const  hansubmit= async(e)=>{
     e.preventDefault();


 
      var accl = {...log.acc};
      // accl = JSON.stringify(accl);
  
     
    await instance.post('/login',accl)
    .then(response=>{
     
      if (response.data.msg == "Success1") {
        localStorage.setItem(process.env.REACT_APP_USERACC , response.data.data);
        SetError('')
        navigate("/Dashboard");
        SetLog({acc:{Email:'',Password:''}});
      } else if (response.data.msg == "Success") {
        // console.log(response.data);
           localStorage.setItem(process.env.REACT_APP_USERACC , response.data.data.user_id);
        
        SetError('')
        navigate("/My-profile");
        SetLog({acc:{email:'',password:''}})
       }else{
        SetError('Incorrect entry.')
       }
     })
     }

    
  
  

  // var visibilityState =  if(err) {"visible"}else{ "hidden"};
  return (
    <div>
      <div>
        <div className="header">
          <img src={logo} alt="" className="logo" />
          <div className="menu">
            <div className="togglemenu"> Don't have an Account? </div>
            <Link to="/" className="btn btn-outline-dark ">Register</Link>
          </div>
        </div>
      </div>
      <div className="body bg-cover" style={
       { backgroundImage: `url(${img2})`}
      
      }>
        <div className="regform">
          <h1 className="reg"> Login Now</h1>
         
           <FormControl error sx={{ m: 1, width: '25ch' }} variant="standard"  >
           <InputLabel htmlFor="standard-adornment-email">Enter Email</InputLabel>
          <Input
          
            id="standard-adornment-email"
            type= 'text' 
            onChange={hanChange}
          value={log.acc.email}
          name="email" 
          />
          <FormHelperText>{err}</FormHelperText>
          </FormControl>
          <FormControl sx={{ m: 1, width: '25ch' }} variant="standard">
          <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
          <Input
            id="standard-adornment-password"
            type={showPassword ? 'text' : 'password'}
            onChange={hanChange}
            value={log.acc.password}
            name="password"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText>{err}</FormHelperText>
        </FormControl>
          <button className='matbut' onClick={hansubmit}>Login</button>
        </div>
        {/* <img src={img2} alt="" className="bbg" /> */}
      </div>
      <h4 className="footer tgg">
        {"  "}
        Copyright © 2023
        Tamizh Manamaalai{"   "}
        All rights reserved.
        {"   "}
        <br />{""}
         Design By{" "}

        <a href="http://skyhost.co.in/" className="link">
         
        Skyhost Technologies.
        </a>{" "}
      </h4>
    </div>
  );
}
