import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Maillist from './maillist';
import Tab from './tab';




export default function Msg({MSG1}) {
    const [message, setMessage] = useState([]);
    const [activeTab, setActiveTab] = useState(0);
    const [sent, setSent] = useState([]);
    const [reciv, setReciv] = useState([]);
    const user = localStorage.getItem(process.env.REACT_APP_USERACC);
    
 
    const handleTabClick = (index) => {
        setActiveTab(index );
    };
    const tabs = [
        { label: "Sent" },
        { label: "Recieved" },
        // { label: "Shortlisted" },
       
    ];
    
    useEffect(() => {
        axios.get(
            process.env.REACT_APP_API + 'requested/' + localStorage.getItem(process.env.REACT_APP_USERACC))
              .then(response => {
                if (response.data.msg === 'success') {
                    setSent(response.data.data);
                }
              });
              axios.get(
                process.env.REACT_APP_API + 'recieve/' + localStorage.getItem(process.env.REACT_APP_USERACC))
                  .then(response => {
                    if (response.data.msg === 'success') {
                        setReciv(response.data.data);
                    }
                  });
    }, [])
    const [rens,setRens] =useState(<Maillist title="sent" message={sent} />);

   
    const renswi = ()=>{
        switch(activeTab) {
            case 0:
                return <Maillist title="sent request" message={sent} />;
            case 1:
              return <Maillist title="recieved request" message={reciv} />;
              case 2:
                return <Maillist title="Shortlisted" message={reciv} />;
            default:
              return null;
          }
    }
    
    return(<>
   <div className='card m-3 p-3 text-center ' style={{ height: "96%"}}>
        <div style={{marginTop:"50px"}}>
        <div className='d-flex position-absolute flex-wrap flex-row  gap-0' style={{marginTop:"-30px"}}>
        {tabs.map((tab, index) => (
                    <Tab
                        key={index}
                        label={tab.label}
                        onClick={() =>
                            handleTabClick(index)
                        }
                        isActive={index === activeTab}
                    />
                ))}
                </div>
                <div className="card mt-2" style={{ height: "96%"}}>
                {renswi()}
             
            </div>
        </div>
    </div>
    

      
    </>)
}