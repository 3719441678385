import { FormControl, FormHelperText, Input, Select, MenuItem, InputLabel } from '@mui/material'
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import lanng from "../assets/json/lang.json";
import relcs from "../assets/json/Rel_cas.json";
import zodiac from "../assets/json/Zodiac.json";
import Button from '../stylecomp/Button';
import TextField from '../stylecomp/TextField';
import InputField from '../stylecomp/InputField';
// import InputLabel from '../stylecomp/InputLabel';
import Select1 from '../stylecomp/Select';
import occupations from '../assets/json/occupations.json'
import Heightlist from '../assets/json/Heightlist.json'
import Educationlist from '../assets/json/Educationlist.json'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import TextField from '@mui/material/TextField';

export default function Edit() {
  // const occupations = 

  var med, med1, med2 = false;

  const [state, SetState] = useState({
    acc: {
      user_id: '',
      name: "",
      phonee: "",
      register: "",
      Gender: "",
      religion: "",
      caste: "",
      dob: '',
      Gothram: '',
      Subcaste: '',
      Email: '',
      password: '',
      nationality: '',
      Langg: '',
      zodiac: '',
      fatherName: '',
      motherName: '',
      star: '',
      lagnam: "",
      height: '',
      weight: '',
      education: "",
      job: "",
      martialStatus: "",
      expertation: "",
      About: "",
      siblings: '',
      hometown: '',
      isloged: 0

    },
  });
  const [fil, setFil] = useState(false);
  const [fil1, setFil1] = useState(false);
  const [fil2, setFil2] = useState(false);

  const navigate = useNavigate();
  let { usid } = useParams();
  // console.log(usid);

  useEffect(() => {

    axios.get(process.env.REACT_APP_API + 'getuser/' + usid)
      .then(response => {
        // console.log(response.data.ds)
        var obj = response.data.ds;
        var acc = { ...state.acc };
        for (let i = 0; i < Object.keys(obj).length; i++) {

          acc[Object.keys(obj)[i]] = obj[Object.keys(obj)[i]];
        }

        SetState({
          acc: acc,
        });


      });


  }, [])
  useEffect(() => {
    setFil(() => {
      var filtered = lanng.filter((obj) => {
        return obj.country === state.acc.nationality;
      });
      // console.log(filtered[0] );
      return filtered[0];
    })
    setFil1(() => {
      var filtered = zodiac.filter((obj) => {
        return obj.rasi === state.acc.zodiac;
      });
      // console.log(filtered[0]);
      return filtered[0];
    })
    setFil2(() => {
      var filtered = relcs.religion.filter((obj) => {
        return obj.name === state.acc.religion;
      });
      // console.log(filtered[0]);
      return filtered[0];
    })
  }, [state])




  const hanchan = (e) => {
    var acc = { ...state.acc };
    acc[e.target.name] = e.target.value;
    SetState({
      acc: acc,
    });
  }
  const hanchan1 = (e) => {
    var acc = { ...state.acc };
    acc[e.target.name] = e.target.value;
    SetState({
      acc: acc,
    });
  }
  if (fil && fil != undefined) {
    med = fil.languages;

  }
  if (fil1 && fil1 != undefined) {
    med1 = fil1;
    // console.log(med1);
  }
  if (fil2 && fil2 != undefined) {
    med2 = fil2.castes;
    // console.log(med2);
  }


  const hanSub = async () => {
    var accl = { ...state.acc };
    accl = JSON.stringify(accl)
    await axios.post(process.env.REACT_APP_API + 'Edit', accl)
      .then(response => {
        // console.log(response.data);
        if (response.data.msg == 'success') {
          navigate(localStorage.getItem('nav-last'))
        } else {
          alert('Fill all Fields')
        }
      })
  }

  return (
    <div className="mt-3 Container">
      <div className='card m-3 my-4 '>
        <h3 className='text-center mb-4 text-muted'>Basic Details</h3>
        <form>
          <Row>
            <Col md={6}>
              <div className='mx-4  mb-4 floating-label '>
                <TextField
                  id="outlined-error-helper-name"
                  name='name'
                  label="Name"
                  value={state.acc.name}
                  onChange={hanchan}
                  disabled={true}
                /></div></Col>
            <Col md={6}>
              <div className='mx-4  mb-4 floating-label'>
                <TextField
                  id="outlined-error-helper-dob"
                  name='dob'
                  label="Date of Birth"
                  type='date'
                  value={state.acc.dob}
                  onChange={hanchan}
                /> </div></Col></Row>
          <Row>
            <Col md={6}>
              <div className='mx-4  mb-4 floating-label'>
                <TextField
                  id="outlined-error-helper-expertation"
                  name='expertation'
                  label="Expertation"
                  value={state.acc.expertation}
                  onChange={hanchan}
                /></div></Col>
            <Col md={6}>
              <div className='mx-4  mb-4 floating-label'>
                <TextField
                  id="outlined-error-helper-phonee"
                  name='phonee'
                  label="Mobile Number"
                  value={state.acc.phonee}
                  onChange={hanchan}
                /></div></Col></Row>
          <Row>
            <Col md={6}>
              <div className='mx-4  mb-4 floating-label'>
                <TextField
                  id="outlined-error-helper-martialStatus"
                  name='martialStatus'
                  label="MartialStatus"
                  type='text'
                  value={state.acc.martialStatus}
                  onChange={hanchan}
                /></div></Col>
            <Col md={6}>
              <div className='mx-4  mb-4 floating-label'>
                <TextField
                  id="outlined-error-helper-hometown"
                  name='hometown'
                  label="Hometown"
                  value={state.acc.hometown}
                  onChange={hanchan}
                />
              </div></Col></Row>
          <Row>
            <Col md={6}>
              <div className='mx-4  mb-4 floating-label'>
                <select
                  className="form-select"
                  id="register"
                  value={state.acc.register}
                  label="Register"
                  onChange={hanchan}
                  name='register'
                  aria-label="Default select example">

                  <option value="My-self">My-self</option>
                  {state.acc.Gender == 'Female' ? <option value="My-Daughter">My-Daughter</option> :
                    <option value="My-Son">My-Son</option>}
                  <option value="My-Friend">My-Friend</option>
                  <option value="My-Relative">My-Relative</option>
                </select>
                <label for="select">Register</label>

              </div></Col>
            <Col md={6}>
              <div className='mx-4  mb-4 floating-label'>
                <select
                  className="form-select"
                  id='Physicalstatus'
                  value={state.acc.Physicalstatus}
                  label="Physical Status"
                  onChange={hanchan}
                  name='Physical Status'
                  aria-label="Default select example">
                  <option value="Normal">Normal</option>

                  <option value="UnNormal">UnNormal</option>
                </select>
                <label for="select">Physical status</label>

              </div></Col></Row>

          <Row>
            <Col md={6}>
              <div className='mx-4  mb-4 floating-label'>
                <TextField
                  id="outlined-error-helper-weight"
                  name='weight'
                  label="Weight"
                  value={state.acc.weight}
                  onChange={hanchan}
                />
              </div></Col>
            <Col md={6}>
              <div className='mx-4  mb-4 floating-label'>
                <Select1
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-height"
                  value={state.acc.height}
                  label="Height"
                  onChange={hanchan}
                  name='height'
                  arr={Heightlist}
                />
              </div></Col></Row>

          <Row>
            <Col md={6}>
              <div className='mx-4  mb-4 floating-label' >

                <select className="form-select" id="Mother Tongue"
                  value={state.acc.Langg}
                  label="Langg"
                  onChange={hanchan}
                  name='Langg' aria-label="Default select example">
                  {med?.map((rec) => {
                    return (
                      <option value={rec} key={rec}  >
                        {rec}
                      </option>
                    );
                  })}
                </select>
                <label for="select">Mother Tongue</label>
              </div></Col>
            <Col md={6}>
              <div className='mx-4  mb-4 floating-label'>
                <select
                  className="form-select"
                  id="EatingHabits"
                  value={state.acc.EatingHabits}
                  label="EatingHabits"
                  onChange={hanchan}
                  name='EatingHabits'
                  aria-label="Default select example">

                  <option value="Normal">Doesn't matter</option>

                  <option value="UnNormal">Non-Veg</option>
                  <option value="UnNormal">Eggetarian</option>
                  <option value="UnNormal">Veg</option>

                </select>
                <label for="select">Eating Habits</label>
              </div></Col></Row>

          <Row>
            <Col md={6}>
              <div className='mx-4  mb-4 floating-label'>
                <select
                  className="form-select"
                  id="SmokingHabits"
                  value={state.acc.SmokingHabits}
                  label="SmokingHabits"
                  onChange={hanchan}
                  name='SmokingHabits'
                  aria-label="Default select example">

                  <option value="Doesn't matter">Doesn't matter</option>

                  <option value="Never smokesl">Never smokes</option>
                  <option value="Smokes occasionally">Smokes occasionally</option>
                  <option value="Smokes regularly">Smokes regularly</option>

                </select>
                <label for="select">Smoking Habits</label>

              </div></Col>

            <Col md={6}>
              <div className='mx-4  mb-4 floating-label'>
                <select
                  className="form-select"
                  id="DrinkingHabits"
                  value={state.acc.DrinkingHabits}
                  label="DrinkingHabits"
                  onChange={hanchan}
                  name='DrinkingHabits'
                >
                  <option value="Doesn't matter">Doesn't matter</option>

                  <option value="Never drinks">Never drinks</option>
                  <option value="Drinks socially">Drinks socially</option>
                  <option value="Drinks regularly">Drinks regularly</option>

                </select>
                <label for="select">Drinking Habits</label>

              </div></Col></Row> </form>
      </div>

      {/*Religion Information  */}

      <div className="mt-3 Container">
        <div className='card m-3 my-4 '>
          <h3 className='text-center mb-4 text-muted'>Religion Information</h3>
          <form>
            <Row>
              <Col md={6}>
                <div className='mx-4  mb-4 floating-label'>

                  <select className="form-select" id="Religion"
                    value={state.acc.religion}
                    label="religion"
                    onChange={hanchan}
                    name='religion' aria-label="Default select example" >
                    {relcs.religion.map((rec) => {
                      return (
                        <option value={rec.name} key={rec.id}  >
                          {rec.name}
                        </option>
                      );
                    })}
                  </select>
                  <label for="select">Religion</label>
                </div></Col>

              <Col md={6}>
                <div className='mx-4  mb-4 floating-label'>
                  <select className="form-select" id="Caste"
                    value={state.acc.caste}
                    label="caste"
                    onChange={hanchan}
                    name='caste' aria-label="Default select example">
                    {med2 && med2.map((rec) => {
                      return (
                        <option value={rec.name} key={rec.id}  >
                          {rec.name}
                        </option>
                      );
                    })}
                  </select>
                  <label for="select">Caste</label>
                </div></Col></Row>
            <Row>
              <Col md={6}>
                <div className='mx-4  mb-4 floating-label'>
                  <TextField
                    id="outlined-error-helper-Subcaste"
                    name='Subcaste'
                    label="Subcaste"
                    value={state.acc.Subcaste}
                    onChange={hanchan}
                  />
                </div></Col>
              <Col md={6}>
                <div className='mx-4  mb-4 floating-label'>
                  <TextField
                    id="outlined-error-helper-Gothram"
                    name='Gothram'
                    label="Gothram"
                    value={state.acc.Gothram}
                    onChange={hanchan}
                  />
                </div></Col></Row>
            <Row>
              <Col md={6}>
                <div className='mx-4  mb-4 floating-label'>
                  <TextField
                    id="outlined-error-helper-Dosham"
                    name='Dosham'
                    label="Dosham"
                    value={state.acc.Dosham}
                    onChange={hanchan}
                  /></div></Col>
              <Col md={6}>
                <div className='mx-4  mb-4 floating-label'>


                  {med1 && <select
                    className="form-select"
                    id="demo-simple-select-helper"
                    value={state.acc.star}
                    label="Star"
                    onChange={hanchan}
                    name='star'
                    aria-label="Default select example" >


                    <option value={med1.star1}   >
                      {med1.star1}
                    </option>
                    <option value={med1.star2}   >
                      {med1.star2}
                    </option>
                    <option value={med1.star3}   >
                      {med1.star3}
                    </option>

                  </select>

                  } <label for="select">Star</label>

                </div></Col></Row>
            <Row>
              <Col md={6}>
                <div className='mx-4  mb-4 floating-label'>
                  <select
                    className="form-select"
                    id="lagnam"
                    value={state.acc.lagnam}
                    label="Lagnam"
                    onChange={hanchan}
                    name='lagnam'
                    aria-label="Default select example" >

                    {zodiac.map((rec) => {
                      return (
                        <option value={rec.rasi} key={rec.id}  >
                          {rec.rasi}
                        </option>
                      );
                    })}
                  </select>
                  <label for="select">Lagnam</label>
                </div></Col>
              <Col md={6}>
                <div className='mx-4  mb-4 floating-label'>
                  <select
                    className="form-select"
                    id="zodiac"
                    value={state.acc.zodiac}
                    label="zodiac"
                    onChange={hanchan}
                    name='zodiac'
                    aria-label="Default select example" >

                    {zodiac.map((rec) => {
                      return (
                        <option value={rec.rasi} key={rec.id}  >
                          {rec.rasi}
                        </option>
                      );
                    })}
                  </select>
                  <label for="select">Zodiac</label>
                </div></Col></Row>
            <Row>
              <Col md={6}>
                <div className='mx-4  mb-4 '>
                  <input type="checkbox" className='mx-3' name='isloged'  onChange={hanchan1} />

                  <label> Willing to marry from same Caste </label>
                </div></Col></Row>
                <Row> 
                <Col md={6}>
                  <div className='mx-4  mb-4 floating-label'>
                    <TextField
                      id="outlined-error-helper-PlaceofBirth"
                      name='PlaceofBirth'
                      label="Place of Birth"
                      value={state.acc.PlaceofBirth}
                      onChange={hanchan}
                    /></div>
                </Col>
                <Col md={6}>
                  <div className='mx-4  mb-4 floating-label'>
                    <TextField
                      id="outlined-error-helper-TimeofBirth"
                      name='TimeofBirth'
                      label="Time of Birth"
                      value={state.acc.TimeofBirth}
                      onChange={hanchan}
                    /></div>
                </Col></Row></form>
        </div></div>
      {/* Groom Location */}
      <div className="mt-3 Container">
        <div className='card m-3 my-4  '>
          <h3 className='text-center mb-4 text-muted'> Groom Location</h3>
          <form>
            <Row>
              <Col md={6}>
                <div className='mx-4  mb-4 floating-label'>
                  <select className="form-select" id="nationality"
                    value={state.acc.nationality}
                    label="nationality"
                    onChange={hanchan}
                    name='nationality' aria-label="Default select example">
                    {lanng.map((rec) => {
                      if (rec.country === 'India') {
                        return (
                          <option value={rec.country} key={rec.country} selected>
                            {rec.country}
                          </option>
                        );
                      } else {
                        return (
                          <option value={rec.country} key={rec.country} >
                            {rec.country}
                          </option>
                        );
                      }
                    })}
                  </select>
                  <label for="select">Nationality</label>

                </div></Col>
              <Col md={6}>
                <div className='mx-4  mb-4 floating-label'>
                  <select
                    className="form-select"
                    id="demo-simple-select-State"
                    value={state}
                    label="state"
                    onChange={hanchan}
                    name='State'
                  >
                  </select>
                  <label for="select">State</label>
                </div></Col></Row>
            <Row>
              <Col md={6}>
                <div className='mx-4  mb-4 floating-label'>
                <TextField
                      id="outlined-error-helper-AncestralOrigin"
                      name='AncestralOrigin'
                      label="Ancestral Origin"
                      value={state.acc.AncestralOrigin}
                      onChange={hanchan}
                    />
                  </div></Col>

              <Col md={6}>
                <div className='mx-4  mb-4 floating-label'>
                  <select
                    className="form-select"
                    id="City"
                    value={state.acc.City}
                    label="City"
                    onChange={hanchan}
                    name='City'
                  >
                  </select>
                  <label for="select">City</label>
                </div></Col></Row>

         </form> </div> </div>


      {/* Professional Information */}

      <div className="mt-3 Container">
        <div className='card m-3 my-4 '>
          <h3 className='text-center mb-4 text-muted'> Professional Information</h3>
          <form>
            <Row>
              <Col md={6}>
                <div className='mx-4  mb-4 floating-label'>

                  <Select1
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-education"
                    value={state.acc.education}
                    label="Education"
                    onChange={hanchan}
                    name='education'
                    arr={Educationlist}

                  /></div></Col>
              <Col md={6}>
                <div className='mx-4  mb-4 floating-label'>

                  <TextField
                    id="outlined-error-helper-EducationDetails"
                    name='EducationDetails'
                    label="EducationDetails"
                    value={state.acc.EducationDetails}
                    onChange={hanchan}
                  />
                </div></Col></Row>
            <Row>
              <Col md={6}>
                <div className='mx-4  mb-4 floating-label'>
                  <TextField
                    id="outlined-error-helper-College"
                    name='College'
                    label="College"
                    value={state.acc.College}
                    onChange={hanchan}
                  />
                </div></Col>
              <Col md={6}>
                <div className='mx-4  mb-4 floating-label'>
                  <select
                    className="form-select"
                    id="EmployedIn"
                    value={state.acc.EmployedIn}
                    label="EmployedIn"
                    onChange={hanchan}
                    name='EmployedIn'
                    aria-label="Default select example" >

                    <option value="Doesn't matter">Government/psu</option>

                    <option value="Never drinks">Private</option>
                    <option value="Drinks socially">Business</option>
                    <option value="Drinks regularly">Defense</option>
                    <option value="Drinks regularly">Self Employed</option>
                    <option value="Drinks regularly">Not Working</option>
                  </select>
                  <label for="select">Employed In</label>
                </div></Col></Row>
            <Row>
              <Col md={6}>
                <div className='mx-4  mb-4 floating-label'>
                  <Select1
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-job"
                    value={state.acc.job}
                    label="Job"
                    onChange={hanchan}
                    name='job'
                    arr={occupations}
                  /> </div></Col>
              <Col md={6}>
                <div className='mx-4  mb-4 floating-label'>
                  <TextField
                    id="outlined-error-helper-OccupationDetails"
                    name='OccupationDetails'
                    label="OccupationDetails"
                    value={state.acc.OccupationDetails}
                    onChange={hanchan}
                  />       </div></Col></Row>

            <Row>
              <Col md={6}>
                <div className='mx-4  mb-4 floating-label'>
                  <TextField
                    id="outlined-error-helper-Organization"
                    name='Organization'
                    label="Organization"
                    value={state.acc.Organization}
                    onChange={hanchan}
                  /> </div></Col>
              <Col md={6}>
                <div className='mx-4  mb-4 floating-label'>
                  <TextField
                    id="outlined-error-helper-AnnualIncome"
                    name='AnnualIncome'
                    label="AnnualIncome"
                    value={state.acc.AnnualIncome}
                    onChange={hanchan}
                  /> </div></Col></Row></form>
        </div>


        {/* Family Details */}

        <div className="mt-3 Container">
          <div className='card m-3 my-4 '>

            <h3 className="text-center mb-4 text-muted">Family Details</h3>
            <form>
              <Row>
                <Col md={6}>
                  <div className='mx-4  mb-4 floating-label'>
                    <TextField
                      id="outlined-error-helper-siblings"
                      name='siblings'
                      label="Siblings"
                      value={state.acc.siblings}
                      onChange={hanchan}
                    /></div>
                </Col>
                <Col md={6}>
                  <div className='mx-4  mb-4 floating-label'>
                    <TextField
                      id="outlined-error-helper-FatherOccupation"
                      name='FatherOccupation'
                      label="FatherOccupation"
                      value={state.acc.FatherOccupation}
                      onChange={hanchan}
                    /></div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div className='mx-4  mb-4 floating-label'>
                    <TextField
                      id="outlined-error-helper-MotherOccupation"
                      name='MotherOccupation'
                      label="MotherOccupation"
                      value={state.acc.MotherOccupation}
                      onChange={hanchan}
                    /></div>
                </Col>
                <Col md={6}>
                  <div className='mx-4  mb-4 floating-label'>
                    <TextField
                      id="outlined-error-helper-motherName"
                      name='motherName'
                      label="MotherName"
                      value={state.acc.motherName}
                      onChange={hanchan}
                    /></div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div className='mx-4  mb-4 floating-label'>
                    <TextField
                      id="outlined-error-helper-fatherName"
                      name='fatherName'
                      label="FatherName"
                      value={state.acc.fatherName}
                      onChange={hanchan}
                    /> </div></Col>

                <Col md={6}>
                  <div className='mx-4  mb-4 floating-label'>

                  <select
                  className="form-select"
                  id="FamilyStatus"
                  value={state.acc.FamilyStatus}
                  label="FamilyStatus"
                  onChange={hanchan}
                  name='FamilyStatus'
                  aria-label="Default select example">

                  <option value=" Middle Class "> Middle Class </option>

                  <option value=" Upper Middle Class  "> Upper Middle Class  </option>
                  <option value=" Rich   "> Rich   </option>
                  <option value="Affluent">Affluent</option>

                </select>
                <label for="select">Family Status</label>
                     </div></Col></Row>
              <Row>
                <Col md={6}>
                  <div className='mx-4  mb-4 floating-label'>
                  <select
                  className="form-select"
                  id="FamilyType"
                  value={state.acc.FamilyType}
                  label="FamilyType"
                  onChange={hanchan}
                  name='FamilyType'
                  aria-label="Default select example">

                  <option value="Joint Family  ">Joint Family  </option>

                  <option value=" Nuclear Family "> Nuclear Family </option>
                  <option value="Others ">Others </option>
                

                </select>
                <label for="select">Family Type</label>
                    </div> </Col>
                <Col md={6}>
                  <div className='mx-4  mb-4 floating-label'>
                  <select
                  className="form-select"
                  id="FamilyValues"
                  value={state.acc.FamilyValues}
                  label="FamilyValues"
                  onChange={hanchan}
                  name='FamilyValues'
                  aria-label="Default select example">

                  <option value="Orthodox ">Orthodox </option>

                  <option value=" Traditional"> Traditional</option>
                  <option value="Moderate ">Moderate </option>
                  <option value="Liberal">Liberal</option>

                </select>
                <label for="select">Family Values</label>
                     </div></Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div className='mx-4  mb-4 floating-label'>
                    <TextField
                      id="outlined-error-helper-FamilyLocation"
                      name='FamilyLocation'
                      label="FamilyLocation"
                      value={state.acc.FamilyLocation}
                      onChange={hanchan}
                    /> </div></Col>
                <Col md={6}>
                  <div className='mx-4  mb-4  floating-label'>
                    <TextField
                      id="outlined-error-helper-About"
                      name='About'
                      label="About"
                      type='text'
                      value={state.acc.About}
                      onChange={hanchan}
                    /> </div></Col>
              </Row>
            </form>
          </div>
        </div>
      </div>



      <div className="mt-3 Container">
        <div className='card m-3 my-4 '>
          <h3 className='text-center mb-4 text-muted'>About My Family</h3>
          <p>This filled your Family Details</p>
        </div></div>
      <div className="mt-3 Container">
        <div className='card m-3 my-4 '>
          <h4 className='text-center mb-4 text-muted'>Hobbies and Interests</h4>
          <p>This filled your day to day Hobbies and Interests so share your Experience</p>
        </div>
      </div>
      <div className='mt-4 w-100'>
        <div className='d-flex mt-4 gap-4 justify-content-center'>
          <Button color='primary' variant='contained' onClick={hanSub}>Change</Button>
          <Button color='error' variant='contained' onClick={() => {
            navigate(localStorage.getItem('nav-last'))
          }}>Close</Button>
        </div></div>

    </div>

  )
}



// martialStatus:"",
// expertation:"",
// About:"",
// siblings:'',
// hometown:'',