import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
// import { Button } from 'primereact/button';
// import 'react-image-crop/dist/ReactCrop.css' 
import Button from '@mui/material/Button';

import Stack from '@mui/material/Stack';

import { useNavigate } from 'react-router-dom';



export default function Procard1({tdata,tdata1 }){
    var path = process.env.REACT_APP_PATH;
    const navigate = useNavigate();

    const [ttdata, setData]= useState('');
    const [visible, setVisible]= useState(false);
    const [profile, setprofile]= useState('');
    const [profile1, setprofile1]= useState('');
    const [fileList, setFileList] = useState(null);
    useEffect(() => {
        setData(tdata)
       if (tdata1) {
        
        setprofile1(tdata1.images.split(','))
        setprofile(path+tdata1.profile)
       }else{
        setprofile(path+'public/images/01.webp')
       }
      
       }, [tdata])
       
      
function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}
const [file, setFile] = useState('');
const [file1, setFile1] = useState('');
const [fileName, setFileName] = useState("");

// const saveFile = (e) => {
//   setFile(e.target.files[0]);
//   setFileName(e.target.files[0].name);
// };
const [fname,setFName] = useState("");


// const history = useNavigate();
useEffect(() => {
    setFName(localStorage.getItem(process.env.REACT_APP_USERACC));
}, [])



const setimgfile = (e)=>{
    setFile(e.target.files[0])
}
const setimgfile1 = (e)=>{
    setFile1(e.target.files[0])
    setTimeout(() => {
        uploadFile1();
    }, 1000*2);
}
const uploadFile =  (e) => {
    e.preventDefault();
    var formData = new FormData();
    formData.append("photo",file)
    // formData.append("fname",fname);
const url =  process.env.REACT_APP_API+'upload/'+localStorage.getItem(process.env.REACT_APP_USERACC);
    const config = {
        headers:{
            "Content-Type":"multipart/form-data"
        }
    }
      const res =  axios.post(url,formData)
      .then(res=>{
    //   console.log(res.data);
    var path = process.env.REACT_APP_PATH;
      var mad = path+res.data.path;
      setprofile(mad);
      setVisible(false);
    })
  };
  const uploadFile1 =  (e) => {
   
    // e.preventDefault();
    var formData = new FormData();
    formData.append("photos",file1)
    // formData.append("fname",fname);
const url =  process.env.REACT_APP_API+'upload1/'+localStorage.getItem(process.env.REACT_APP_USERACC);

      const res =  axios.post(url,formData)
      .then(res=>{
        var mad = res.data.path;
      setprofile1(mad);
      })
  


  };
  


const profileClick =()=>{
    setVisible(true)
}
// console.log(profile1);
// console.log(tdata1);
const files = fileList ? [...fileList] : [];

    return(
        <>
            
        
            <div className="card">
                        <Dialog header="crop Image" visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)}>
                        <div className='upldimg'>
                       
                            crop image
                           
                            </div>
                        <Stack direction="row" alignItems="center" spacing={2}>
                       
                            <br></br>
                            <form onSubmit={uploadFile}>
                        <Button variant="contained" component="label">
                            Upload
                            <input hidden accept="image/*" name='photo' type="file" id='profimg' onChange={setimgfile} />
                        </Button>
                        {/* <IconButton color="primary" aria-label="upload picture" component="label">
                            <input hidden accept="image/*" type="file" />
                            <PhotoCamera />
                        </IconButton> */}
                        <Button label='save'  type='submit'>save</Button>
                        </form>
                        </Stack>
                        
                        </Dialog>
						<div className="card-body">

                        <div className="d-flex">
                        <div className='flex-1 px-4'>
                        <div className="img-con">
                        <div style={{backgroundImage: `url(${profile})`, width:'250PX' ,height : '250PX'}} className="profilepic">
                        <button className='editbtn' onClick={profileClick}>edit / Upload</button>
                        </div>
           
                        </div>
                        {profile1 &&<div className="d-flex mt-3">
       {profile1.map((item,index)=>{
        return <div key={index} style={{backgroundImage: `url(${path+item})`, width:'38px' ,height : '38PX'}} className="pxx"></div>
       })}
       </div>}</div>
                        <div className='flex-2 px-4'>
                        <div className='h4 text-muted' >{ttdata.Gender == "Female" ? 'Ms. ': 'Mr. '}{ttdata.name}</div>
                        <div className='p doc-speciality' >{ttdata.Qualification}</div>
                        <p className='doc-location'>{getAge(ttdata.dob)} Yrs,{ttdata.height}</p>
                        <p className='doc-location'>{ttdata.religion}, {ttdata.caste}</p>
                        <p className='doc-location'>{ttdata.education}</p>
                        
                        <p className='doc-location'>{ttdata.zodiac}, {ttdata.star}</p>
                        <p className='doc-location'><i className="fas fa-map-marker-alt px-2 " ></i>  
                        {ttdata.hometown} , {ttdata.nationality} 
                        </p>
                        </div>
                        <div className='flex-2  px-4'>
                 <h4 className='text-muted' >Professional Details</h4>
                 <p className='doc-location pt-2'>{ttdata.job}</p>
                 <p className='doc-location pt-2'>{ttdata.expertation}</p>

                 <Button color='secondary'  className='mx-2' onClick={()=>{localStorage.setItem('nav-last' , '/My-profile/');navigate('edit/'+ttdata.user_id)}}>edit</Button>
               
                 
                 {/* <Button color='success' component="label">
                            Upload Images
                            <input hidden accept="image/*" name='photos' type="file" id='profimg' onChange={setimgfile1}  />
                        </Button> */}

                <Button color='secondary'  className='mx-2' onClick={()=>{sessionStorage.setItem('nav-last' , '/My-profile/');navigate('setting/'+ttdata.user_id)}}>Privacy Setting</Button>

                        </div>
                        
							
						</div>
                        </div>
					</div> 
        </>
    );
} 




