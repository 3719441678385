import React from 'react';
import Msg from '../comp/msg';
import Sidebar from '../comp/sidebar';
import SearchBar from '../comp/SearchBar';


export default function Inbox({tdata,MSG1}){
    return(
        <>
        <div className="p-0  px-4 py-2 bg-theme position-relative" >       
            <div className='h4 text-light'> My Inbox</div>
            <SearchBar/>
            </div>
        <div className='container'>
          

            <div className='d-flex'>
                {/* <div className='flex-1'>
                {tdata &&
                <Sidebar tdata={tdata} />}
                </div> */}
                <div className='flex-1'>
                <Msg MSG1={MSG1} />
                </div>
            </div>
</div>
        </>
    )
}