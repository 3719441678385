import React, { useEffect, useState } from 'react';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import Procard1 from '../comp/profile-card1';

import Button from '@mui/material/Button';
import ProfileMeter from './ProfileMeter';
import { CapFirst } from '../comp/Helper';
import Procard4 from '../comp/profile-card4';
import Procard3 from '../comp/profile-card3';



export default function Homealone({tdata,tdata1}) {

  
  //  console.log(tdata.data1);


  const [profile, setprofile]= useState('');
  // const [ttdata, setData]= useState('');
  const [randomProfile, setRandomProfile] = useState([]);
  const navigate = useNavigate();
  
  var path = process.env.REACT_APP_PATH; 
  useEffect(() => { 
    if (tdata.data1.profile !== '') {
      setprofile(path+tdata.data1.profile)
    }   
   
    
   
   }, [tdata])

   //--------------------------------------------------------
   
   useEffect(() => {
    
    var accl = {};
    accl.user_id = localStorage.getItem(process.env.REACT_APP_USERACC);
    accl.GENDER = localStorage.getItem('GENDER');
    accl.isloged = 1;
    accl.minage =18;
    accl.maxage =100;
    accl.religion ="Any";
    accl.caste = "Any";
    accl.Martial ="0";


    //  console.log(accl);
    axios.post(process.env.REACT_APP_API + 'matches', JSON.stringify(accl))
      .then(response => {
        console.log(response.data.data);
        if (response.data.msg === 'success') {
          setRandomProfile(response.data.data)

        }
      });
      }, []);                                            // Empty dependency array ensures the effect runs only once after the initial render

      


      const [randata, setData]= useState({data:'',data1:''});  
    useEffect(() => {
        axios.get(process.env.REACT_APP_API+'suggest/'+localStorage.getItem(process.env.REACT_APP_USERACC))
        .then(response=>{
       
    //  console.log(response.data.dss);
       
         var ddata = response.data.ds;
         var ddata1 = response.data.ds1;
         setData({
             data:ddata,
             data1:ddata1
         })
     });
 
     
    }, []);


    //-----------------------Style Sheet---------------------------
  const styles = `  
  .box2 {
    border: 1px solid #ec7608;
    border-radius:9px;
    padding: 2rem;
    background-color: #f1ebe0 !important;
  }
.wishes{
  font-family: Goudy Old Style;
    letter-spacing: 1px;    color:#ff5100;
    text-shadow: 0 0 1px #EEBA0F, 0 0 8px #615A5C;
    text-shadow: 0 0 1px #EEBA0F, 0 0 4px #F3EBA7;
    font-size: 42px;
    
}
.photo{
  background-size: cover;
  background-position: center center;
  width: 200px;
  height: 200px;
  margin-top: -130px;
  margin-left: 37px;
  border: 1px solid #ec7608;
  border-radius: 9px;
}

.click{
  float: right !important;
  margin-top: 1.5rem !important;
}

     `;


     //---------------------------------------------------
      
      return (

      <div>
           
        <div className=" p-0  px-6 py-2 bg-theme" >     
        <style>{styles}</style>  
          <div className='bigfont h4 text-light' > My Home</div>
           
        </div>
        <div className='container'>

            <div>
            
            <ProfileMeter tdata={tdata.data} />         
              {/* Display the UserProfile component */}
            </div>
            


        <div className= "box2  d-flex" style={{marginBottom:"50px",backgroundColor: "#f1ebe0"}}>
        <div style={{width:"600px"}}> 

        <div className= "profilephoto photo" style={{
            backgroundImage: `url(${profile})`,
           
            
            }} > </div>
            </div>
        <div className="wishes " >  
                  
                  <h2 className=" container mt-2 welcome" > Hi, {CapFirst(tdata.data.name)}! Welcome,    
                  We hope you find a fulfilling relationship soon and experience more  joy in life...</h2>
                  
                </div>
        </div>
     

        
        
        
        
        
       
        <div className= "box2">
        {/* <h2>content </h2> */}

          
            <h2>Random Profile Display</h2>
            {/* {randomProfile.length>=0 && randomProfile?.map((item,index)=>
              
              <div key={index}>
                <strong>ID:</strong> {item.user_id} <br />
                <strong>Name:</strong> {item.name} <br />
                <strong>Age:</strong> {item.dob} <br />                     
                
              </div>)} */}

                        
                        
                        


              {/* <div >
                <strong>ID:</strong> {randomProfile[0]?.user_id} <br />
                <strong>Name:</strong> {randomProfile[0]?.name} <br />
                <strong>Age:</strong> {randomProfile[0]?.dob} <br />                      
              </div> */}

<div className=' mt-4 '>

<Procard3 tdata={randata.data} tdata1={randata.data1}  />
</div>
<div className=' mt-4'>
<h4 className='text-success'>Personal Information</h4>

<Procard4 tdata={randata.data}  />

</div>

            
          

        </div>



        <div className='click'>
          {/* <h4 className='head text-muted' >Setting</h4> */}

          <Button color='secondary'  className=' btn mx-2' onClick={()=>{localStorage.setItem('nav-last' , '/My-profile/');navigate('FAQ/'+tdata.data.user_id)}}>F A Q</Button>
          <Button color='secondary'  className=' btn mx-2' onClick={()=>{localStorage.setItem('nav-last' , '/My-profile/');navigate('helpline/'+tdata.data.user_id)}}>help</Button>
          

        </div>

        

</div>
      </div>
      );
      
    };
    

    
   
    
    
