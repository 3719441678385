import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ProfileMeter = ({tdata}) => {
 
  const profileFields = [
    
    'name',
    'height',
    'weight',
    'Gender',
    'nationality',
    'Langg',
     
    'phonee',
    
    'religion',
    'caste',
    'dob',
    'Gothram',
    'Subcaste',
    'Email',
    'password',
    
    'zodiac',
    'fatherName',
    'motherName',
    'star',
    
    'education',
    'job',
    'martialStatus',
   
    'hometown',
 
    ];     // Define the profile fields

  
  const [userProfile, setUserProfile] = useState(                    // State to manage the completion percentage
   tdata
  );

  
  const [completionPercentage, setCompletionPercentage] = useState(0);   

                                                                    
  useEffect(() => {                                               // Effect to fetch user profile information when the component mounts
    // fetchUserProfile();
    // console.log(tdata);
    setUserProfile(tdata);
    calculateCompletionPercentage();                              // Effect to update completion percentage whenever user profile changes

  }, [tdata]);                                                        // Empty dependency array ensures the effect runs only once after the initial render

  // const fetchUserProfile = async () => {                         // Fetch user profile information from the database using Axios
  //   try { 
  //     const response = await axios.get(process.env.REACT_APP_API + 'getuser/' + localStorage.getItem(process.env.REACT_APP_USERACC));     // Replace with your actual API endpoint
  //     const userData = response.data.ds;                            // Assuming the response.data is an object containing user profile data
  //     setUserProfile(userData);
  //   } catch (error) {
  //     console.error('Error fetching user profile:', error);
  //   }
  // };                                                   
  // useEffect(() => {
  //   calculateCompletionPercentage();                              // Effect to update completion percentage whenever user profile changes
  // }, [userProfile]);

  
  const calculateCompletionPercentage = () => {                   // Function to calculate the completion percentage
    const filledFields = profileFields.filter(field => userProfile[field] !== '').length;
    // console.log(userProfile);
   
    const totalFields = profileFields.length;
    const percentage = (filledFields / totalFields) * 100;
    setCompletionPercentage(percentage);
  };


    //-----------------------Style Sheet------------------------
  const styles = `

  .meterfont {
    margin: 1rem;     
    font-family: "Lucida Console", "Courier New", monospace;
  }

  .meterbox {
      
    margin: 1rem;     
    content: 1rem;
  }

  `;


     //--------------------------------------------------------


  return (
    <div style={{position:"relative",height:"120px"}}>
        <style>{styles}</style> 
      {/* <h2>User Profile</h2> */}

      {/* Render user profile information */}
      {/* {profileFields.map(field => (
        <div key={field}>
          <a>{field.charAt(0).toUpperCase() + field.slice(1)}:</a> {userProfile[field]}
        </div>
      ))} */}

      {/* Render completion meter */}
      <div style={{position:"absolute",right:0}}>

        <div className='meterfont'>Profile Completion: {parseInt(completionPercentage)} %</div>

        <div className='meterbox'style={{ width: '250px', border: '1px solid #ccc', borderRadius: '5px', overflow: 'hidden' }}>
          <div
            style={{
              width: `${completionPercentage}%`,
              backgroundColor: '#4CAF50',
              height: '20px',
              transition: 'width 0.9s',
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default ProfileMeter;
