import React, { useEffect, useState } from 'react'

export default function Button({color,onClick,children}) {
    const [colorr,setcolor] = useState('btn btn-primary');

    useEffect(() => {
        if (color === 'primary') {
            setcolor('btn btn-primary') 
        } else {
            setcolor('btn btn-secondary') 
        }
    
    }, [color])
    

  return (
    <div><button className={colorr} onClick={onClick}>{children}</button></div>
  )
}
