import React, { Component, useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import logo from "../assets/logo.png";
import img2 from "../assets/bg.png";
import relcs from "../assets/json/Rel_cas.json";
import { Link } from "react-router-dom";

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';


export default function Regz({ hansubmitl, hanChan, statel, filt,err,datee }) {
  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked(!checked);
  };
  return (
    <div>
      

      <div className="body bg-cover" style={
       { backgroundImage: `url(${img2})`}
      
      }>
        <div className="regform">
          <h1 className="reg"> Register 1</h1>
          <FormControl error sx={{ m: 1, width: '25ch' }} variant="standard"  >
           <InputLabel htmlFor="standard-adornment-date">Date of birth</InputLabel>
          
          <Input
          
            id="standard-adornment-date"
            type= 'Date' 
            onChange={hanChan}
          value={statel.dob}
          name="dob" 
          defaultValue={datee}
          min="1980-01-01" max={datee}
          />
            {statel.acc.dob == '' && <FormHelperText>{err}</FormHelperText>}
          </FormControl>
          <FormControl fullWidth>
          <InputLabel variant="standard" htmlFor="uncontrolled-native">
          Religion
        </InputLabel>
          <NativeSelect   name="religion"
            id="relig"
            onChange={hanChan}
            value={statel.religion}
          >
         
        
            {relcs.religion.map((rec) => {
              return (
                <option key={rec.id} value={rec.name}>
                  {rec.name}
                </option>
              );
            })}
            </NativeSelect>
            </FormControl>


            <FormControl fullWidth>
          <InputLabel variant="standard" htmlFor="uncontrolled-native">
          Caste * 
        </InputLabel>
          <NativeSelect
            id="cas"
            className=""
            name="caste"
            onChange={hanChan}
            value={statel.caste}
          >
            {filt.castes.map((rec) => {
              return (
                <option
                  key={rec.id}
                  value={rec.name != "-- Select --" && rec.name}
                >
                  {rec.name}
                </option>
              );
            })}
           </NativeSelect>
           {statel.acc.caste == '' && <FormHelperText>{err}</FormHelperText>}
           <div className="d-flex gap-3">
           <input type="checkbox" checked={statel.acc.value} name='isloged' value='0' onChange={hanChan}/>
           <label> Willing to marry from same Caste </label></div>
            </FormControl>
            
            <FormControl error sx={{ m: 1, width: '25ch' }} variant="standard"  >
           <InputLabel htmlFor="standard-adornment-Subcaste">Sub Caste</InputLabel>
          <Input
          
            id="standard-adornment-Subcaste"
            type= 'text' 
            onChange={hanChan} name="Subcaste"
          value={statel.Subcaste}
       
          />
          
          </FormControl>
          <FormControl error sx={{ m: 1, width: '25ch' }} variant="standard"  >
           <InputLabel htmlFor="standard-adornment-Gothram">Gothram</InputLabel>
          <Input
          
            id="standard-adornment-Gothram"
            type= 'text' 
            onChange={hanChan} name="Gothram"
          value={statel.Gothram}
          />
         
          </FormControl>
         
            
          <button className='matbut' onClick={hansubmitl}>Next Page</button>
        </div>
        
      </div>
      <h4 className="footer tgg">
        {"  "}
        Copyright © 2023
        Tamizh Manamaalai{"   "}
        All rights reserved.
        {"   "}
        <br />{""}
         Design By{" "}

        <a href="http://skyhost.co.in/" className="link">
         
        Skyhost Technologies.
        </a>{" "}
      </h4>
    </div>
  );
}
