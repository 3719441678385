import React, { useEffect, useState } from 'react';
import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import logo from "../assets/logo.png";
import Inbox from './inbox';
import Matches from './matches';
import Show from './show';
import Welcome from './Welcome';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Button } from '@mui/material';
import Edit from './Edit';
import axios from 'axios';
import relcs from "../assets/json/Rel_cas.json";
import ChangePassword from './PrivacySetting';
import Preference from './preferenceSet';
import Homealone from './homealone';
import Helpline from './Helpline';
import HomeIcon from '@mui/icons-material/Home';
import JoinInnerIcon from '@mui/icons-material/JoinInner';
import InboxIcon from '@mui/icons-material/Inbox';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import FAQ from './FAQ';

export default function PageRoute(params) {
  const [tdata, setData] = useState({ data: '', data1: '' });
  const [tdatta, setDatta] = useState('');
  const [MSG1, setMsg1] = useState('a');

  const [state, SetState] = useState({
    acc: {
      minage:18,
      maxage:100,
      religion:"Any",
      caste:"Any",
      Martial:"0"
    }});
  const [filt, setFilt] = useState(relcs.religion[0]);


  const hanChan = (e) => {
    if (e.target.name === "religion") {
    const filtered = relcs.religion.filter((obj) => {
      return obj.name === document.getElementById("relig").value;
    });
    setFilt(filtered[0]);
  }
  const acc = { ...state.acc };
  acc[e.target.name] = e.target.value;
  SetState({
    acc: acc,
  });
  };




  useEffect(() => {
    axios.get(process.env.REACT_APP_API + 'getuser/' + localStorage.getItem(process.env.REACT_APP_USERACC))
      .then(response => {
        if (response.data.msg === 'success') {

          var ddata = response.data.ds;
          var ddata1 = response.data.ds1;

          //  console.log(ddata);

          localStorage.setItem('GENDER', response.data.ds.Gender);
          setData({
            data: ddata,
            data1: ddata1
          })
        }
      });

  }, []);
  useEffect(() => {
    var accl = {};
    accl.user_id = localStorage.getItem(process.env.REACT_APP_USERACC);
    accl.GENDER = localStorage.getItem('GENDER');
    accl.isloged = 1;
    accl.minage = state.acc.minage?state.acc.minage:18;
    accl.maxage = state.acc.maxage?state.acc.maxage:100;
    accl.religion = state.acc.religion;
    accl.caste = state.acc.caste;
    accl.Martial = state.acc.Martial;


    //  console.log(accl);
    axios.post(process.env.REACT_APP_API + 'matches', JSON.stringify(accl))
      .then(response => {
        // console.log(response.data.data);
        if (response.data.msg === 'success') {
          setDatta(response.data)

        }
      });


  }, [state]);
  // console.log(tdatta);
  const navigate = useNavigate();
  if (localStorage.getItem(process.env.REACT_APP_USERACC) === null) {
    navigate("/login");
  }
  const hanlogout = () => {

    localStorage.clear();

    navigate("/login");

  };
  return (
    <>
      <nav>
        <div className='nav togglemenu'>
          <div className='d-flex sk_gp-5 ' >
            <img src={logo} alt="" className="logo" />
            <Link to="home" className='togglemenu'>Home</Link>
            <Link to="matches" className='togglemenu' >My Matches</Link>
            <Link to="inbox" className='togglemenu' >inbox</Link>
            <Link to="/My-profile" className='togglemenu'>My Profile</Link>
            <button className='matbut togglemenu' onClick={hanlogout}><ExitToAppIcon /> Logout</button>
          </div>

        </div>
      </nav>
      <div style={{paddingBottom:"70px"}}>
      <Routes>
        <Route
          path="/"
          element={
            <Welcome filt={filt} tdata={tdata} />
          }
        />
        <Route
          path=":id"
          element={
            <Show />
          }
        />

        <Route
          path="matches"
          element={
            <Matches filt={filt} hanChan={hanChan} tdataa={tdatta} tdata={tdata} />
          } />
        <Route
          path="inbox"
          element={
            <Inbox filt={filt} tdata={tdata} MSG1={MSG1} />
          } />
        <Route
          path="edit/:usid"
          element={
            <Edit />
          } />

        <Route
          path="home"
          element={
            <Homealone tdata={tdata} />
          } />

        {/* <Route path="preference/:id" element={<Preference />} /> */}
        {/* Other routes go here */}
        <Route path="setting/:id" element={<ChangePassword />} />
        <Route path="home/helpline/:id" element={<Helpline />} />
        <Route path="home/FAQ/:id" element={<FAQ />} />
        {/* More routes if needed */}
        

      
        {/* Other routes go here */}
        {/* <Route path="/privacysetting" component={PrivacySetting} /> */}
        {/* More routes if needed */}
      
      </Routes>

      </div>
      <div className='nav bottomNav'>
        <Link to="home" className='togglemenu'><HomeIcon /></Link>
        <Link to="matches" className='togglemenu' ><JoinInnerIcon /></Link>
        <Link to="inbox" className='togglemenu' ><InboxIcon /></Link>
        <Link to="/My-profile" className='togglemenu' ><AccountBoxIcon /></Link>
      </div>

    </>
  );

};