import React from 'react'

export default function TextField({ type,name,label,id,value,onChange}) {
  return (
    <div className='mx-4  mb-4 floating-label'>
    
    <input className='form-control' type={type? type:'text'} name={name}  id={name} value={value} onChange={onChange} />
    <label for={name}>{label}</label>
   </div>
  )
}
