

import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { red } from '@mui/material/colors';
import MenuIcon from '@mui/icons-material/Menu';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import Button from '@mui/material/Button';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { FormControl, Input, InputLabel, NativeSelect, TextField } from '@mui/material';
import relcs from "../assets/json/Rel_cas.json";


const theme = createTheme({
  palette: {

    anger: {
      // This is green.A700 as hex.
      main: '#f03737',
    },
  },
});

export default function Sidebar({ tdata, hanChan, filt }) {


  const navigate = useNavigate();
  if (localStorage.getItem(process.env.REACT_APP_USERACC) === null) {
    navigate("/login");
  }
  const hanlogout = () => {

    localStorage.clear();

    navigate("/login");

  };
  // console.log(tdata);

  var path = process.env.REACT_APP_PATH;

  // console.log(tdata);
  const color1 = red[500];


  //-----------------------Age function--------------------------

  const ages = [];
  for (let age = 20; age <= 71; age ++) {
    ages.push(age);
  }
  
  //-----------------------Style Sheet---------------------------
  const styles = `

.sidebar {
    
   padding-bottom: 4rem !important;
   

  //  position: fixed;
  //  overflow-y: auto;
  
}

.fixedbar {

   
   overflow-y: auto;
   

`;


//---------------------------------------------------
  
  return (
    <div className='container m-2 p-2 mobileTop ' >
    <style>{styles}</style>
    <div className='fixedbar'>
    <div className='sidebar card jsc ' style={{padding:"20px 60px",marginTop:"0.50rem"}}>
      {/* <img src={path+tdata.data1.profile}  /> */}
      <div className="img-con2">
      <div style={{ backgroundImage: `url(${path + tdata.data1.profile})`, width: '250PX', height: '250PX' }} className="profilepic2 mobilepic">
        </div>
      </div>
      <h4 className='text-muted text-center'>
        {tdata.data.name}

      </h4>
        <Link to="/My-profile" className='text-center nav-link togglemenu'> View profile</Link>
        <ThemeProvider theme={theme}>
          {/* <Button color="anger" variant='text' onClick={hanlogout}><ExitToAppIcon color="anger" /></Button> */}
        </ThemeProvider>

        {/* -----------------------------search by filter------------------------------------- */}

        <div>
          {/* <div>
            <label style={{padding:'4px', margin:'10%'}} htmlFor="search">Search:</label>
        <input
          type="text"
          id="search"
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
        />
          </div> */}
          <div>
            <label className='input doc-location pt-2' htmlFor="religion" style={{ marginTop: "40px" }}>Religion Filter:</label>
            <FormControl fullWidth>
              {/* <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Religion
              </InputLabel> */}
              <NativeSelect name="religion"
                id="relig"
                onChange={hanChan}
              // value={statel.religion}
              >


                {relcs.religion.map((rec) => {
                  return (
                    <option key={rec.id} value={rec.name}>
                      {rec.name}
                    </option>
                  );
                })}
              </NativeSelect>
            </FormControl>

            {/* ---------------Caste Filter----------------- */}

            <label className='input doc-location pt-2' htmlFor="agefil" style={{ marginTop: "40px" }}>Caste Filter:</label>

            <FormControl fullWidth>
              {/* <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Caste *
              </InputLabel> */}
              <NativeSelect
                id="cas"
                className=""
                name="caste"
              onChange={hanChan}
              // value={statel.caste}
              >
                {filt &&filt.castes.map((rec) => {
                  return (
                    <option
                      key={rec.id}
                      value={rec.name != "-- Select --" && rec.name}
                    >
                      {rec.name}
                    </option>
                  );
                })}
              </NativeSelect>
            </FormControl>

            {/* --------------------------Age Filter-------------------- */}


            <label className='input doc-location pt-2' htmlFor="agefil" style={{ marginTop: "40px" }}>Age Filter:</label>

            <FormControl error sx={{ m: 1, width: '25ch' }} variant="standard"  >
              <InputLabel htmlFor="standard-adornment-minage">Min Age</InputLabel>
              <NativeSelect
                id="standard-adornment-minage"
                type='number'
                onChange={hanChan}
                name="minage"
              >

                <option value="" disabled>
                Select an Age
                </option>
                {ages.map((age) => (
                <option key={age} value={age}>
                {age}
                </option>
                ))}
              </NativeSelect>
            </FormControl>

            <FormControl error sx={{ m: 1, width: '25ch' }} variant="standard"  >
              <InputLabel htmlFor="standard-adornment-maxage">Max Age</InputLabel>
              <NativeSelect

                  defaultValue={36}
                id="standard-adornment-maxage"
                type='number'
                onChange={hanChan}
                name="maxage"
              >

                <option value="" disabled>
                Select an Age
                </option>
                {ages.map((age) => (
                <option key={age} value={age}>
                {age}
                </option>
                ))}
              </NativeSelect>
            </FormControl>

            {/* --------------------------Maried or not-------------------- */}
            <label className='input doc-location pt-2' htmlFor="agefil" style={{ marginTop: "40px" }}>Marital Filter:</label>
            <FormControl fullWidth>
              {/* <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Maried Status:
              </InputLabel> */}
              <NativeSelect
                id="Martialid"
                className=""
                name="Martial"
                
                onChange={hanChan}
              // value={statel.caste}
              >
                <option value="0">Any</option>
                <option value="unmarried">unmarried</option>
                <option value="Widows">Widows</option>
                <option value="divorce">divorce</option>
                <option value="Await divorce">Await divorce</option>
              </NativeSelect>
            </FormControl>

            {/* ----------------------------Setting-------------------------- */}

            {/* <button style={{padding:'1%', margin:'30%'}}> Privacy Setting </button> */}


          </div>

        </div>
        </div>
      </div></div>






  )

}


