import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
// import { Button } from 'primereact/button';
// import 'react-image-crop/dist/ReactCrop.css'
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Stack from '@mui/material/Stack';
import DoneIcon from '@mui/icons-material/Done';
import profilepic from '../assets/img/01.webp';
import { Box } from '@mui/system';
import { Fab, SvgIcon } from '@mui/material';

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CallIcon from '@mui/icons-material/Call';
export default function Procard3({tdata,tdata1 }){
    var path = process.env.REACT_APP_PATH; 

    const [ttdata, setData]= useState('');
    const [visible, setVisible]= useState(false);
    const [profile, setprofile]= useState('');
    
    useEffect(() => {
        setData(tdata)

        if (tdata1.profile !== '') {
            setprofile(tdata1.profile)
        }else{
            setprofile('public/images/01.webp')
        }
       
       
       }, [tdata])
       
      
function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}
const [file, setFile] = useState('');
const [fileName, setFileName] = useState("");

// const saveFile = (e) => {
//   setFile(e.target.files[0]);
//   setFileName(e.target.files[0].name);
// };
const [fname,setFName] = useState("");


// const history = useNavigate();
useEffect(() => {
    setFName(localStorage.getItem(process.env.REACT_APP_USERACC));
}, [])

const setdata = (e)=>{
   
}

const setimgfile = (e)=>{
    setFile(e.target.files[0])
}



const profileClick =()=>{
    setVisible(true)
}

// console.log(tdata1);
const requestpro= (m)=>{
    // console.log(m);
    var f= localStorage.getItem(process.env.REACT_APP_USERACC);
    var dd = {
        user_id:f,
        requested_id:m
    }
    axios.post(process.env.REACT_APP_API+'request',dd)
    .then(response=>{
        // console.log(response.data);
    })
        }

    return(
        <>
            
        
            <div className="card">
           

                       
						<div className="card-body">

                        <div className="d-flex">
                        <div className="img-con">
                        <div style={{backgroundImage: `url(${profile!== ''&& path+ profile})`, width:'250PX' ,height : '250PX'}} className="profilepic">
                      
                        </div>
       
                        </div>
                        <div className='flex-2 px-4'>
                        <div className='h4 text-muted' >{ttdata.Gender == "Female" ? 'Ms. ': 'Mr. '}{ttdata.name}</div>
                        <div className='p doc-speciality' >{ttdata.Qualification}</div>
                        <p className='doc-location'>{getAge(ttdata.dob)} Yrs,{ttdata.height}</p>
                        <p className='doc-location'>{ttdata.religion ==""?"not specified":ttdata.religion}, {ttdata.caste}</p>
                        <p className='doc-location'>{ttdata.education}</p>
                        
                        <p className='doc-location'>{ttdata.zodiac ==""?"zodiac not specified":ttdata.zodiac}, {ttdata.star==""?"star not specified":ttdata.star}</p>
                        <p className='doc-location'><i className="fas fa-map-marker-alt px-2 " ></i>  
                        {ttdata.hometown} , {ttdata.nationality} 
                        </p>
                        </div>
                        <div className='flex-2  px-4'>
                 <h4 className='text-muted' >Professional Details</h4>
                 <p className='doc-location pt-2'>{ttdata.job}</p>
                 <p className='doc-location pt-2'>{ttdata.expertation}</p>
               <div></div>
                 <h5 className='text-muted' >Connect</h5>
                 <Fab color="success" aria-label="success">
                 <DoneIcon onClick={()=>requestpro(ttdata.user_id)} />
                </Fab>
                 <Fab color="primary" aria-label="success" className='m-2'>
                 <CallIcon />
                 </Fab>
                 <Fab color="success" aria-label="success">
                 <WhatsAppIcon />
                </Fab>
                        </div>
                        
							
						</div>
                        </div>
					</div> 
        </>
    );
} 


{/* <h6 className='text-center text-info'>Like {ttdata.Gender == "Female" ? 'Her': 'His'} Profile?</h6>
<h4 className='text-success text-center' >Connect Now</h4> */}