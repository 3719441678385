import React, { Component, useState } from 'react';
import axios from 'axios';



  
  const ChangePassword = () => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [message, setMessage] = useState('');
  
    const handleChangePassword = async () => {

          
          
          if (oldPassword === newPassword) {

            setMessage('old and newPassword are not to be same!');

          }

          else if (newPassword === password2) {
            
            setMessage('Both NewPasswords are matching!');
          } 
          
          else {
            setMessage('Both NewPasswords are not match.');
          }

          

      try {
        const response = await axios.post('http://localhost:5000/change-password', {
          oldPassword: oldPassword,
          newPassword: newPassword,
        });
  
        setMessage(response.data.message);
      } catch (error) {
        console.error('Error changing password:', error);
      }
    };

    const styles = `
    .container {
      
      margin: auto;
      padding: 20px;
     
    }

    .bigfont {
      
      text-align: center;
    }

    .label {
      display: block;
      margin-bottom: 5px;
      align-content: center;
    }

    .input {
      width: 40%;
      padding: 5px;
      margin-bottom: 10px;
      box-sizing: border-box;
    }

    .button {
      background-color: #ee7300;
      color: #fff;
      padding: 10px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }

    .message {
      margin-top: 10px;
    }

    .box{
      margin: 3%;
      margin-top: 6%;
      border: 1px solid #ec7608;
      border-radius:9px;
      padding: 5%;
      padding-left: 36%;

    }

    .onbutt{
      display:block;
      margin: 3% !important;
      margin-left: 13% !important;
      background-color:#e46b02
     

    }

  `;
  
    return (

        

      <div>
             <style>{styles}</style>

        <div className=" p-0  px-4 py-2 bg-theme" >       
            <div className='bigfont h4 text-light' > Change Password</div>
            </div>
        {/* <h1>Change Password</h1> */}
        <div className="box">
        <label className='label text-muted'>Old Password:</label>
        <input className='input doc-location pt-2' type="password" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} />
  
        <label className='label text-muted'>New Password:</label>
        <input className='input doc-location pt-2' type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />

        <label className='label text-muted'>Confirm New Password:</label>
        <input className='input doc-location pt-2' type="password" value={password2} onChange={(e) => setPassword2(e.target.value)} />
        
     
  
        <button className='onbutt matbut togglemenu' onClick={handleChangePassword}>Change Password</button>

        

        <div>
          <strong className='message text-muted'>Message:</strong> {message}
        </div>

        </div>
      </div>
    );
  };
  
  export default ChangePassword;
  

          
         
    