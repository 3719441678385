import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// index.js or App.js


const SearchBar = ({ onSearch }) => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');

  const handleInputChange = (e) => {

    setSearchTerm(e.target.value);
    // onSearch(e.target.value); // Pass the search term to the parent component if needed
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      // Call the search function when Enter is pressed
      // onSearch(searchTerm);
      var nav = '/My-profile/'+searchTerm;
      navigate(nav);
    }
  };
  return (
    
    <div className="d-flex   justify-content-end  align-items-center">
    <div className="searchbar top-50  translate-middle-y" style={{right:"62px"}}>
    <div className="col ">
    <div className="search-bar">
      <input
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
      />
      </div>
      </div>
      </div>
      

</div>
  );
};


export default SearchBar;