import React, { Component, useEffect, useState } from "react";
import lanng from "../assets/json/lang.json";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import Regl from "../comp/reg1";
import Regz from "../comp/regz";
import relcs from "../assets/json/Rel_cas.json";
import Rege from "../comp/rege";
import Regr from "../comp/regr";
import Welcome from "./Welcome";
import logo from "../assets/logo.png";
import axios from "axios";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import zodiac from "../assets/json/Zodiac.json";
import { useBeforeunload } from 'react-beforeunload';

export default function Home(props) {
 
  function subtractYears(years) {
    const date = new Date();
    date.setFullYear(date.getFullYear() - years);
    var mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
  }
  
  // Feb 20, 2022
 
  
  
  const newDate = subtractYears(18);
  const [errl,seterrl] = useState('')
 const [datee,setdatee] = useState(newDate)
  const [visible, setVisible]= useState(false);
  const [err , SetError ]=useState('')
  const [err2 , SetError2 ]=useState('')
  const [state, SetState] = useState({
    acc: {
      name: "",
      phonee: "",
      register: "My-self",
      Gender: "Male",
      religion: "Hindu",
      caste: "",
      dob:'',
      Gothram:'',
      Subcaste:'',
      Email:'',
      password:'',
      nationality	:'India',
      Langg:'Tamil',
      zodiac:'Mesham',
      fatherName:'',
      motherName:'',
      star:'Ashwini',
      lagnam:"Mesham",
      height:'',
      weight:''	,
      education:"",
      job:"",
      martialStatus:"",
      expertation:"",
      About:"",
      siblings:'',
      hometown:'',
      isloged:0

    },
  });
  const navigate = useNavigate();
  const hansubmit = (e) => {
    e.preventDefault();
    
    if (state.acc.name !== '' && state.acc.phonee !== ''  ) {
      SetError('')
      if (state.acc.phonee.length > 9) {
        seterrl('Enter 10 digit');
      }else{
      navigate("/reg-1");}
    }else{
    
      SetError('fill this field')
    }

    
  };
  const hansubmitl = (e) => {
    e.preventDefault();

    if (state.acc.caste !== ''  ) {
      SetError('')
      navigate("/reg-2");
    }else{
    
      SetError('fill this field')
    }

  };
  const hansubmite = (e) => {
    e.preventDefault();
    var bb = state.acc.Email;
   
      
        if (state.acc.Email !== '' && state.acc.Password != ''  ) {
          SetError('')
          if (state.acc.Email.includes("@") && state.acc.Email.includes(".")) {
            navigate("/reg-3");
          } else {
            SetError2('Incorrect Email')
          }
        
          
        }else{
          SetError2('fill this field')
          SetError('fill this field')
        }

  };
  const hansubmitr = (e) => {
    e.preventDefault();
    // navigate("/reg-4");
    
    if (state.acc.name != '' && state.acc.phonee != ''  ) {
      SetError('')
      navigate("/reg-4");
    }else{
    
      SetError('fill this field')
    }

  };
 
  const [filt, setFilt] = useState(relcs.religion[0]);
  const [fil, setFil] = useState(lanng[95].languages);
  const hanrel = () => {
    const filtered = relcs.religion.filter((obj) => {
      return obj.name === document.getElementById("relig").value;
    });
    setFilt(filtered[0]);
  };
  const hanrel1 = () => {
    const filtered = lanng.filter((obj) => {
      return obj.country === document.getElementById("nationality").value;
    });
    // console.log(lanng);
    setFil(filtered[0].languages);
    
  };
  const [fil3, setFil3] = useState(zodiac[0]);
  const hanrel2 = () => {
    var filtered3 = zodiac.filter((obj) => {
      return obj.rasi === document.getElementById("zodiac").value;
    });
    // console.log(lanng);
    setFil3(filtered3[0]);
    
  };

  const hanChan = (e) => {
    if (e.target.name === "religion") {
      hanrel();
    }
    if (e.target.name === "nationality") {
      hanrel1();
    }
    if (e.target.name === "zodiac") {
      hanrel2();
    }
    if (e.target.name === "isloged") {
      e.target.value = e.target.checked?1:0;
    }
    const acc = { ...state.acc };
    if (document.getElementById("Gender")) {
      var gen = document.getElementById("Gender");

      if (e.target.value == "My-Daughter") {
        acc["Gender"] = "Female";
        gen.value = "Female";
        gen.disabled = true;
      } else if (e.target.value === "My-Son") {
        acc["Gender"] = "Male";
        gen.value = "Male";
        gen.disabled = true;
      } else {
        gen.disabled = false;
      }
    }
    acc[e.target.name] = e.target.value;
    if (e.target.name === "isloged") {
      acc[e.target.name] = JSON.parse(e.target.value);
    }
    SetState({
      acc: acc,
    });
  };

  const hanChanp = (e) => {
    if (e.target.value.length <10) {
      
   
    const acc = { ...state.acc };
    acc["phonee"] = e.target.value;
    SetState({
      acc: acc,
    }); }
  };
  const hanfsubmit = async(e)=>{
    e.preventDefault();
    
    if (state.acc.fatherName != '' && state.acc.motherName != ''  ) {
      SetError('')
    var accl = {...state.acc} ;
    accl = JSON.stringify(accl)

  await axios.post(process.env.REACT_APP_API+'register',accl)
  .then(response=>{
   
    //  console.log(response.data);
    if (response.data.msg === 'success') {
          localStorage.setItem(process.env.REACT_APP_USERACC , response.data.user_id);
          SetState({
            acc: {
              name: "",
              phonee: "",
              register: "My-self",
              Gender: "Male",
              religion: "Hindu",
              caste: "",
              dob:'',
              Gothram:'',
              Subcaste:'',
              Email:'',
              password:'',
              nationality	:'India',
              Langg:'Tamil',
              zodiac:'Mesham',
              fatherName:'',
              motherName:'',
              star:'Ashwini',
              lagnam:"Mesham",
              height:'',
              weight:''	,
              education:"",
              job:"",
              martialStatus:"",
              expertation:"",
              About:"",
              siblings:'',
              hometown:'',
              isloged:false
            },
          })
          navigate("/My-profile");
        }

   })
  }else{
    
    SetError('fill this field')
  }
    
  }

  useEffect(() => {
    const acc = { ...state.acc };
    acc["dob"] = newDate;
    SetState({
      acc: acc,
    }); 
  }, [])
  
  // useBeforeunload(() => {
  //   navigate('/')
  // }); 

  return (
    <div>
      <div className="header">
        <img src={logo} alt="" className="logo" />
        <div className="menu">
          <div className="togglemenu"> Already have an Account? </div>
          <Link to="/login" className="btn btn-outline-dark "> LOG IN</Link>
        </div>
      </div>
      <Routes>
        <Route
          path="/"
          element={
            <Regl
              hansubmit={hansubmit}
              statel={state}
              hanChan={hanChan}
              hanChanp={hanChanp}
              err={err}
              errl={errl}
            />
          }
        />
        <Route
          path="/reg-1"
          element={
            <Regz
              hansubmitl={hansubmitl}
              statel={state}
              hanChan={hanChan}
              filt={filt}
              err={err}
              datee={datee}
            />
          }
        />
        <Route
          path="/reg-2"
          element={
            <Rege
            hansubmite={hansubmite}
              statel={state}
              hanChan={hanChan}
              fil={fil}
              fil3={fil3}
              err={err}
              err2={err2}
            />
          }
        />
        <Route
          path="/reg-3"
          element={
            <Regr
            hanChan={hanChan}
            hanfsubmit={hanfsubmit}
            statel={state}
            err={err}
            />
            
          }
        />
         
      </Routes>
      {/* <Dialog header=" Fill this the field" visible={visible} style={{ width: '30vw' }} onHide={() => setVisible(false)}>
      </Dialog> */}
			

      
    </div>
  );
}
