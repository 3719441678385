import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import profilepic from '../assets/img/01.webp';
import { Fab, IconButton, Switch } from '@mui/material';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CallIcon from '@mui/icons-material/Call';
import Sidebar from '../comp/sidebar';
import SearchBar from '../comp/SearchBar';
import SwitchButton from '../comp/switchButton';





export default function Matches({tdata, tdataa,hanChan,filt}) {
    const navigate = useNavigate();
    // const [tdata, setData]= useState('');
    const [profile, setprofile]= useState(null);
  
    // console.log(tdata.data);
    function getAge(dateString) {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }
    var path =process.env.REACT_APP_PATH;
    // console.log(path);
    const proShow=(e)=>{

    };


  
 return(<>
    <div className="p-0  px-4 py-2 bg-theme position-relative" >       
    <div className='h4 text-light'> My Matches</div>
    <SearchBar/>
    </div>

    <div className='container mobnmp'>
    
{tdataa&&

<div className='d-flex'>
<div className='flex-1'>
{tdata &&
                <Sidebar  filt={filt}  hanChan={hanChan} tdata={tdata} />}   
</div>
<div className='flex-3 noscrollbar' style={{height:"92vh",paddingBottom:"40px" , overflowX:"scroll"}}>
{
    tdataa.data.map((rec)=>{
        return (  <div key={rec.name} className='card m-4 p-2' >
        
        <div className="card-body">

                        <div className="d-flex">
                        <div className="img-con" onClick={()=>{
           navigate(`/My-profile/${rec.user_id}`) 
        }}>
                        <div  style={{backgroundImage: `url(${rec.profile!=='' && path+rec.profile})`, width:'250PX' ,height : '250PX'}} className="profilepic">
                        </div>
                        </div>
                        <div className='flex-2 px-4' onClick={()=>{
           navigate(`/My-profile/${rec.user_id}`) 
        }}>
                        <h4 className='h4 text-muted' >{rec.Gender == "Female" ? 'Ms. ': 'Mr. '}{rec.name}</h4>
                        <div className='p doc-speciality' >{rec.Qualification}</div>
                        <p className='doc-location'>{getAge(rec.dob)} Yrs,{rec.height}</p>
                        <p className='doc-location'>{rec.religion}, {rec.caste}</p>
                        <p className='doc-location'>{rec.education}</p>
                        
                     
                        <p className='doc-location'><i className="fas fa-map-marker-alt px-2 " ></i>  
                        {rec.hometown} , {rec.nationality} 
                        </p>
                        </div>
                        <div className='flex-2  px-4'>
                

                <h6 className='text-center text-info'>Like {rec.Gender == "Female" ? 'Her': 'His'} Profile?</h6>
                <h4 className='text-success text-center' >Connect Now</h4>
                <SwitchButton rec={rec} />
                        </div>
                        
							
						</div>
                        </div>
        
        </div>
        
        
        )
    }) 
}
</div>

<div className='mobview'>
  

    {/* <Wrapper /> */}
</div>


</div>

}

    </div>
    </>
);
}