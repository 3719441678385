import logo from './logo.svg';
import './App.css';
import "./style.css";
import "./assets/css/bootstrap.min.css";
// import '@fortawesome/fontawesome-free/css/all.min.css';
import "primereact/resources/themes/lara-light-indigo/theme.css";  
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css"; 
import "react-phone-input-2/lib/style.css";
import Home from "./page/home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./page/login";
import PageRoute from "./page/pageroute";
import Dashboard from "./page/Dashboard";
// import '@fontsource/roboto/300.css';
// import '@fontsource/roboto/400.css';
// import '@fontsource/roboto/500.css';
// import '@fontsource/roboto/700.css';  


function App() {
  
  var dam = false;
    if (localStorage.getItem(process.env.REACT_APP_USERACC)) {
      dam = false;
    } else {
      dam = true;
    }
    return (
      <>
      <BrowserRouter > 
      <Routes>
      <Route  path="/*" element={<Home />} />
      <Route  path="/login" element={<Login />} />
      <Route
            path="/My-profile/*"
            element={
              <PageRoute />
              
            }
          />
           <Route
            path="/Dashboard/*"
            element={
              <Dashboard />
            }
          />
          {dam ? <Route path="/*" component={<PageRoute />} />:<Route path="/*" component={<Home />} />}
      </Routes>
      </BrowserRouter>
      <h4 className="footer togglemenu">
          {"  "}
          Copyright © 2023
          Tamizh Manamaalai{"   "}
          All rights reserved.
  
          <br />
           Design By{" "}
  
          <a href="http://skyhost.co.in/" className="link">
           
          Skyhost Technologies.
          </a>{" "}
        </h4>
      </>
    );
  }
  
  export default App;
  
  