
import React, { useEffect, useState } from 'react';
import { json, Link, Route, Routes, useNavigate } from 'react-router-dom';

import Procard1 from '../comp/profile-card1';
import Procard2 from '../comp/profile-card2';
import { AES, enc } from 'crypto-js';
import Sidebar from '../comp/sidebar';

export default function Welcome({tdata}) {
   


// console.log(tdata.data1);
    


    
      
   
    return(
        <>
       


       				
			<div className="p-0  px-4 py-2 bg-theme" >       
            <div className='h4 text-light'> My Profile</div>
            </div>
            <div className='mobview m-0'> {tdata &&
                <Sidebar tdata={tdata} />}</div>
            {/* { tdata.data && */}
            <div>
            <div className='container mt-4'>

                <Procard1 tdata={tdata.data} tdata1={tdata.data1}  />
            </div>
            <div className='container mt-4'>
            <h4 className='text-success'>Personal Information</h4>

            <Procard2 tdata={tdata.data}  />
            </div>
            </div>
            {/* } */}

  




        {/*<div className='kodd'>
        <div className='kod'>

        </div>
        <div className='kod1'>
            
        </div>
        </div>*/}
        </>
    )
};