import { Rowing } from '@mui/icons-material';
import { Button } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';

export default function Free({data,hancclick}) {
  const navigate = useNavigate();
   
const [records, setrecords] = useState(data);
// console.log(data);

    const columns=[ {
        name: 'S.no',
        cell: (row, index) => index+1  
      },
        {
            name:"user_id",
            selector:row => row.user_id,
            sortable:true
        },
        {
            name:"Name",
            selector:row => row.name,
            sortable:true
        },
        {
            name:"Email",
            selector:row => row.Email,
            sortable:true
        },
        {
            name:"Gender",
            selector:row => row.Gender,
            sortable:true
        },
        {
            name:"Edit",
            selector:row => <Button  onClick={()=>{localStorage.setItem('nav-last' , '/Dashboard/');navigate('edit/'+row.user_id)}}>Edit</Button>,
            sortable:true
        },
        {
            name:"Delete",
            selector:row =><Button color='error' name='delete' value={row.user_id} onClick={hancclick}>Delete</Button>,
            sortable:true
        }
    ];
    

    function hanFilter(event) {

        const newdata = data.filter(
            row=>{
                return row.name.toLowerCase().includes(event.target.value.toLowerCase())||
                row.Email.toLowerCase().includes(event.target.value.toLowerCase()) ||
                row.Gender.toLowerCase().includes(event.target.value.toLowerCase()) ||
                row.user_id.toLowerCase().includes(event.target.value.toLowerCase())
            }
        )
        setrecords(newdata);
    }
  return (
    <div className='container m-5'>
<div className='text-end'><label className='m-3'>Search: </label><input type='text' className='m-3' onChange={hanFilter} /></div>

        <DataTable
        columns={columns}
        data={records||data}
        pagination
        searchable
        fixedHeader
        ></DataTable>
    </div>
  )
}
