import React, { Component, useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import logo from "../assets/logo.png";
import img2 from "../assets/bg.png";
import lanng from "../assets/json/lang.json";
import zodiac from "../assets/json/Zodiac.json";
import { Link } from "react-router-dom";

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export default function Rege({ hansubmite, hanChan, statel, fil, err ,fil3 , err2}) {
  // console.log(fil3);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  return (
    <div>
     

      <div className="body bg-cover" style={
       { backgroundImage: `url(${img2})`}
      
      }>
        <div className="regform">
          <h1 className="reg"> Register 2</h1>
      
          <FormControl fullWidth>
          <InputLabel variant="standard" htmlFor="uncontrolled-native">
          Nationality
        </InputLabel>
          <NativeSelect
            name="nationality"
            id="nationality"
            onChange={hanChan}
            value={statel.nationality}
          >
            {lanng.map((rec) => {
              if (rec.country === 'India') {
                return (
                  <option  value={rec.country} key={rec.country}  
                  // selected
                  >
                    {rec.country}
                  </option>
                );
              } else {
                return (
                  <option  value={rec.country} key={rec.country} >
                    {rec.country}
                  </option>
                );
              }
              
            })}
            </NativeSelect>
            </FormControl>
   
          <FormControl fullWidth>
          <InputLabel variant="standard" htmlFor="uncontrolled-native">
          Mother Language
        </InputLabel>
          <NativeSelect
            id="Langg"
            className=""
            name="Langg"
            onChange={hanChan}
            value={statel.Langg}
          >
            {fil.map((rec) => {
              if (rec === 'Tamil') {
                return (
                  <option
                  key={rec}
                  value={rec}
                  // selected
                >
                  {rec}
                </option>
                );
              } else {
                return (
                  <option
                  key={rec}
                  value={rec}
                >
                  {rec}
                </option>
                );
              }
              return (
                <option
                  key={rec}
                  value={rec}
                  
                >
                  {rec}
                </option>
              );
            })}
            </NativeSelect>
            </FormControl>
            <FormControl fullWidth>
          <InputLabel variant="standard" htmlFor="uncontrolled-native">
          zodiac
        </InputLabel>
          <NativeSelect
            name="zodiac"
            id="zodiac"
            onChange={hanChan}
            value={statel.zodiac}
          >
            {zodiac.map((rec) => {
             return(
                  <option  value={rec.rasi} key={rec.id} >
                    {rec.rasi}
                  </option>)
            })}
            </NativeSelect>
            </FormControl>
   
          <FormControl fullWidth>
          <InputLabel variant="standard" htmlFor="uncontrolled-native">
          Mother Language
        </InputLabel>
          <NativeSelect
            id="star"
            className=""
            name="star"
            onChange={hanChan}
            value={statel.star}
            defaultValue={statel.star}
          >
            <option value={fil3.star1}>
                  {fil3.star1}
                </option>
                <option value={fil3.star2}>
                  {fil3.star2}
                </option>
                <option value={fil3.star3}>
                  {fil3.star3}
                </option>
               
               
            </NativeSelect>
            </FormControl>
            {/* <FormControl error sx={{ m: 1, width: '25ch' }} variant="standard"  >
            <InputLabel htmlFor="standard-adornment-zodiac">zodiac</InputLabel>
          <Input id="standard-adornment-zodiac"
           type="text" placeholder="Enter zodiac" name="zodiac" onChange={hanChan}
          value={statel.zodiac} />
          <FormHelperText>{err}</FormHelperText>
          </FormControl>
          <FormControl error sx={{ m: 1, width: '25ch' }} variant="standard"  >
            <InputLabel htmlFor="standard-adornment-star">star</InputLabel>
          <Input id="standard-adornment-star"
          type="text" placeholder="Enter star" name="star" onChange={hanChan}
          value={statel.star} />
          <FormHelperText>{err}</FormHelperText>
          </FormControl> */}
          <FormControl error sx={{ m: 1, width: '25ch' }} variant="standard"  >
            <InputLabel htmlFor="standard-adornment-Email">Email *</InputLabel>
          <Input id="standard-adornment-Email"
          type="text" placeholder="Enter Email" name="Email" onChange={hanChan}
          value={statel.Email} />
          {statel.acc.Email == '' &&<FormHelperText>{err}</FormHelperText>}
          </FormControl>
          {/* <FormControl error sx={{ m: 1, width: '25ch' }} variant="standard"  >
            <InputLabel htmlFor="standard-adornment-Password">Password</InputLabel>
          <Input id="standard-adornment-Password"
           type="password" placeholder="Enter Password" name="password" onChange={hanChan}
          value={statel.password} />
          <FormHelperText>{err}</FormHelperText>
          </FormControl> */}
          <FormControl sx={{ m: 1, width: '25ch' }} variant="standard">
          <InputLabel htmlFor="standard-adornment-password">Password *</InputLabel>
          <Input
            id="standard-adornment-password"
            type={showPassword ? 'text' : 'password'}
            onChange={hanChan}
            value={statel.Password}
            name="Password"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          {statel.acc.Password == '' &&<FormHelperText>{err}</FormHelperText>}
        </FormControl>
          <button className='matbut' onClick={hansubmite}>Next Page</button>
        </div>
        
      </div>
      <h4 className="footer tgg">
        {"  "}
        Copyright © 2023
        Tamizh Manamaalai{"   "}
        All rights reserved.
        {"   "}
        <br />{""}
         Design By{" "}

        <a href="http://skyhost.co.in/" className="link">
         
        Skyhost Technologies.
        </a>{" "}
      </h4>
    </div>
  );
}
