import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { json, Link, Route, Routes, useNavigate, useParams } from 'react-router-dom';

import Procard1 from '../comp/profile-card1';
import Procard2 from '../comp/profile-card2';
import { AES, enc } from 'crypto-js';
import Procard4 from '../comp/profile-card4';
import Procard3 from '../comp/profile-card3';

export default function Show({pros}) {
    let { id } = useParams();
    const [tdata, setData]= useState({data:'',data1:''});  
    useEffect(() => {
        axios.get(process.env.REACT_APP_API+'getuser/'+id)
        .then(response=>{
       
    //  console.log(response.data.dss);
       
         var ddata = response.data.ds;
         var ddata1 = response.data.ds1;
         setData({
             data:ddata,
             data1:ddata1
         })
     });
 
     
    }, []);


// console.log(tdata.data1);
    


    
      
   
    return(
        <>
       


       				
			<div className="p-0  px-4 py-2 bg-theme" >       
            <div className='h4 text-light'> My Matches</div>
            </div>

           
            <div>
         
            <div className='container mt-4 '>

                <Procard3 tdata={tdata.data} tdata1={tdata.data1}  />
            </div>
            <div className='container mt-4'>
            <h4 className='text-success'>Personal Information</h4>

            <Procard4 tdata={tdata.data}  />
           
            </div>
            </div>
      
          

  




 
        </>
    )
};