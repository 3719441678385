import React, { Component, useState } from 'react';
import axios from 'axios';
import "../assets/css/bootstrap.min.css";
import Accordion from 'react-bootstrap/Accordion';

const FAQ = () => {




    //-----------------------Style Sheet---------------------------
    const styles = `
   
    .bigfont {
      
      text-align: center;
    }

    .card {
      
        margin-left: 12% !important;
        font-family : Roboto;
        border-radius: 7px !important;
                                
      }

    .container{
        margin-top: 2% !important;
    }

    .guide{
        margin-top: 2% !important;
        font-family : Roboto;
        text-align: center;
    }

    `;
    //-----------------------------------------------------------
    return (

        <div>


            <div className=" p-0  px-4 py-2 bg-theme" >
                <style>{styles}</style>
                <div className='bigfont h4 text-light' > Frequently Asking Questions </div>
            </div>

            <div className="guide" >
            click on the question you want -to see the answer
            </div>

            <div className='container mt-4'>

                <Accordion defaultActiveKey=" " >
                    <Accordion.Item eventKey="0">
                        <Accordion.Header >Question :  How Long Does It Take to Find a Match?</Accordion.Header>
                        <Accordion.Body>
                        <strong> Answer: </strong>  The time to find a match varies for each individual. Patience is key, as finding the right match may take time.
                        </Accordion.Body>
                    </Accordion.Item>
                    
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>  Question :   How to Create an Effective Matrimonial Profile?</Accordion.Header>
                        <Accordion.Body>
                            <strong>Answer:</strong> Include accurate information, highlight key details like education and profession, and provide clear photos. Be honest about preferences and expectations for a successful match.
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="2">
                        <Accordion.Header>  Question :   How Does Matrimony Site Work?</Accordion.Header>
                        <Accordion.Body>
                            <strong>Answer:</strong> Users create profiles with personal details and partner preferences. The site's algorithm suggests potential matches based on compatibility. Users can then connect with each other and decide whether to pursue a relationship.
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="3">
                        <Accordion.Header>  Question :   Can I Search for Matches Myself?</Accordion.Header>
                        <Accordion.Body>
                            <strong>Answer:</strong> Yes, users can perform manual searches based on criteria like age, religion, and more. Advanced filters help narrow down preferences.
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="4">
                        <Accordion.Header>  Question :   What Precautions Should I Take While Communicating?</Accordion.Header>
                        <Accordion.Body>
                            <strong>Answer:</strong> Be cautious about sharing personal details too soon. Communicate within the site's messaging system initially and only share contact information when comfortable.
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="5">
                        <Accordion.Header>  Question :   Is Registration on Matrimonial Sites Free?</Accordion.Header>
                        <Accordion.Body>
                            <strong>Answer:</strong> Yes, Our Tamizh Manamaalai Matrimonial sites offer free registration, allowing users to create profiles and explore basic features. However, premium features and services may require a subscription or payment.
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="6">
                        <Accordion.Header>  Question :   How Can I Initiate Contact with a Potential Match?</Accordion.Header>
                        <Accordion.Body>
                            <strong>Answer:</strong> Our Tamizh Manamaalai Matrimonial sites provide options like expressing interest, sending personalized messages, to initiate contact with potential matches.
                        </Accordion.Body>
                    </Accordion.Item>

                </Accordion>
            </div>
        </div>
    );
};

export default FAQ;