import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import SwitchButton from './switchButton';

export default function Maillist({title,message}) {
    var path =process.env.REACT_APP_PATH;
    const navigate = useNavigate();
    const hanclick=(user_id)=>{
        // alert(user_id)
        // var nav = '/My-profile/'+user_id;
        // navigate(nav);
    }
    useEffect(() => {
   console.log(message);
    }, [])
    

  return (
    <div>
    <h1 style={{textTransform:"capitalize"}} className="mt-4">{title}</h1>
    {message.length <= 0 && <p>No Request</p>}
{message.map((item , index)=>{
    return(
        <div key={index} onClick={()=>hanclick(item.id)}>
            {/* <p  >{item.name} - {item.age}</p> */}
          <div key={item.name} className='card m-4 p-2' >
        <div className="card-body">
                        <div className="d-flex">
                        <div className="img-con" onClick={()=>{
           navigate(`/My-profile/${item.user_id}`) 
        }}>
                        <div  style={{backgroundImage: `url(${path+item.profile})`, width:'250PX' ,height : '250PX'}} className="profilepic">
                        </div>
                        </div>
                        <div className='flex-2 px-4' onClick={()=>{
           navigate(`/My-profile/${item.user_id}`) 
        }}>
                        <h4 className='h4 text-muted' >{item.Gender == "Female" ? 'Ms. ': 'Mr. '}{item.name}</h4>
                        <p className='doc-location'>{item.religion}, {item.caste}</p>
                        <p className='doc-location'>{item.education}</p>
                        <p className='doc-location'><i className="fas fa-map-marker-alt px-2 " ></i>  
                        {item.hometown} , {item.nationality} 
                        </p>
                        </div>
                        <div className='flex-2  px-4'>
                <h6 className='text-center text-info'>Like {item.Gender == "Female" ? 'Her': 'His'} Profile?</h6>
                <h4 className='text-success text-center' >Connect Now</h4>
              
                        </div>
						</div>
                        </div>
        </div>
        </div>)
})}

    </div>
  )
}
