import React, { useState } from "react";
 
const Tab = ({ label, onClick, isActive }) => (
    <div
        className={`${isActive ? "tabon_menu corn" : "tab_menu corn"}`}
        onClick={onClick}
    >
        {label} {isActive}
    </div>
);
 
export default Tab;