import React, { Component, useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";


import lanng from "../assets/json/lang.json";
import { Link } from "react-router-dom";
import img2 from "../assets/bg.png";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import zodiac from "../assets/json/Zodiac.json";


export default function Regr({statel,hanChan , hanfsubmit,err }) {
  
  return (
    <div> 
      <div className="body bg-cover" style={
       { backgroundImage: `url(${img2})`}
      
      }>
        <div className="forrm regform">
            <div className="disp">
              <div className="f1">
              <FormControl error sx={{ m: 1, width: '100%' }} variant="standard"  >
           <InputLabel htmlFor="standard-adornment-father">Father Name *</InputLabel>
          <Input id="standard-adornment-father"
          type="text" placeholder="Enter Father Name" name="fatherName"  className="halfinp" onChange={hanChan} />
         {statel.acc.fatherName == '' &&<FormHelperText>{err}</FormHelperText>}
          </FormControl>
              </div>
              <div className="f1">
              <FormControl error sx={{ m: 1, width: '25ch' }} variant="standard"  >
           <InputLabel htmlFor="standard-adornment-Mother">Mother Name *</InputLabel>
          <Input id="standard-adornment-Mother"
                 type="text" placeholder="Enter Mother Name" name="motherName"  className="halfinp" onChange={hanChan} />
                {statel.acc.motherName == '' &&<FormHelperText>{err}</FormHelperText>}
          </FormControl>
              </div>
            </div>
            <div className="disp">
              <div className="f1">
              <FormControl fullWidth>
          <InputLabel variant="standard" htmlFor="uncontrolled-native">
          Enter Lagnam
        </InputLabel>
          <NativeSelect
            name="lagnam" 
            id="lagnam"
            onChange={hanChan}
           
          >
            {zodiac.map((rec) => {
             return(
                  <option  value={rec.rasi} key={rec.id} >
                    {rec.rasi}
                  </option>)
            })}
            </NativeSelect>
            </FormControl>
               
              </div>
              <div className="f1">
              
                <FormControl fullWidth>
          <InputLabel variant="standard" htmlFor="uncontrolled-native">
          Height
        </InputLabel>
          <NativeSelect
            name="height"
            id="height"
            onChange={hanChan}
          >
           <option value="Not Specified">Feet / Inches  </option>
              <option value="4ft 6in / 137 cms">4ft 6in / 137 cms</option>
              <option value="4ft 7in / 139 cms">4ft 7in / 139 cms</option>
              <option value="4ft 8in / 142 cms">4ft 8in / 142 cms</option>
              <option value="4ft 9in / 144 cms">4ft 9in / 144 cms</option>
              <option value="4ft 10in / 147 cms">4ft 10in / 147 cms</option>
              <option value="4ft 11in / 149 cms">4ft 11in / 149 cms</option>
              <option value="5ft / 152 cms">5ft / 152 cms</option>
              <option value="5ft 1in / 154 cms">5ft 1in / 154 cms</option>
              <option value="5ft 2in / 157 cms">5ft 2in / 157 cms</option>
              <option value="5ft 3in / 160 cms">5ft 3in / 160 cms</option>
              <option value="5ft 4in / 162 cms">5ft 4in / 162 cms</option>
              <option value="5ft 5in / 165 cms">5ft 5in / 165 cms</option>
              <option value="5ft 6in / 167 cms">5ft 6in / 167 cms</option>
              <option value="5ft 7in / 170 cms">5ft 7in / 170 cms</option>
              <option value="5ft 8in / 172 cms">5ft 8in / 172 cms</option>
              <option value="5ft 9in / 175 cms">5ft 9in / 175 cms</option>
              <option value="5ft 10in / 177 cms">5ft 10in / 177 cms</option>
              <option value="5ft 11in / 180 cms">5ft 11in / 180 cms</option>
              <option value="6ft / 182 cms">6ft / 182 cms</option>
              <option value="6ft 1in / 185 cms">6ft 1in / 185 cms</option>
              <option value="6ft 2in / 187 cms">6ft 2in / 187 cms</option>
              <option value="6ft 3in / 190 cms">6ft 3in / 190 cms</option>
              <option value="6ft 4in / 193 cms">6ft 4in / 193 cms</option>
              <option value="6ft 5in / 195 cms">6ft 5in / 195 cms</option>
              <option value="6ft 6in / 198 cms">6ft 6in / 198 cms</option>
              <option value="6ft 7in / 200 cms">6ft 7in / 200 cms</option>
              <option value="6ft 8in / 203 cms">6ft 8in / 203 cms</option>
              <option value="6ft 9in / 205 cms">6ft 9in / 205 cms</option>
              <option value="6ft 10in / 208 cms">6ft 10in / 208 cms</option>
              <option value="6ft 11in / 210 cms">6ft 11in / 210 cms</option>
              <option value="7ft / 213 cms">7ft / 213 cms</option>
            </NativeSelect>
            </FormControl>
              </div>
            </div>
            <div className="disp">
              <div className="f1">
              <FormControl error sx={{ m: 1, width: '100%' }} variant="standard"  >
           <InputLabel htmlFor="standard-adornment-Weight">Weight</InputLabel>
          <Input id="standard-adornment-Weight"
                type="text" placeholder="Enter Weight" name="weight"  className="halfinp" onChange={hanChan} />
                  {/* <FormHelperText>{err}</FormHelperText> */}
          </FormControl>
              </div>
              <div className="f1">
              <FormControl fullWidth>
          <InputLabel variant="standard" htmlFor="uncontrolled-native">
          Education
        </InputLabel>
          <NativeSelect
            name="education"
            id="Education"
            onChange={hanChan}
          >
          <option value="Not Specified"> Select </option>								

<option value="Aeronautical Engineering">Aeronautical Engineering</option>
<option value="B.Arch">B.Arch</option>
<option value="BCA">BCA</option>
<option value="BE">BE</option>
<option value="B.Plan">B.Plan</option>
<option value="B.Sc IT/ Computer Science">B.Sc IT/ Computer Science</option>
<option value="B.Tech.">B.Tech.</option>
<option value="Other Bachelor Degree in Engineering / Computers">Other Bachelor Degree in Engineering / Computers</option>
<option value="B.S.(Engineering)">B.S.(Engineering)</option>

<option value="M.Arch.">M.Arch.</option>
<option value="MCA">MCA</option>
<option value="ME">ME</option>
<option value="M.Sc. IT / Computer Science">M.Sc. IT / Computer Science</option>
<option value="M.S.(Engg.)">M.S.(Engg.)</option>
<option value="M.Tech.">M.Tech.</option>
<option value="PGDCA">PGDCA</option>
<option value="Other Masters Degree in Engineering / Computers">Other Masters Degree in Engineering / Computers</option>

<option value="Aviation Degree">Aviation Degree</option>
<option value="B.A.">B.A.</option>
<option value="B.Com.">B.Com.</option>
<option value="B.Ed">B.Ed.</option>
<option value="BFA">BFA</option>
<option value="BFT">BFT</option>
<option value="BLIS">BLIS</option>
<option value="B.M.M.">B.M.M.</option>
<option value="B.Sc.">B.Sc.</option>
<option value="B.S.W">B.S.W</option>
<option value="B.Phil.">B.Phil.</option>
<option value="Other Bachelor Degree in Arts / Science / Commerce">Other Bachelor Degree in Arts / Science / Commerce</option>

<option value="M.A.">M.A.</option>
<option value="MCom">MCom</option>
<option value="M.Ed.">M.Ed.</option>
<option value="MFA">MFA</option>
<option value="MLIS">MLIS</option>
<option value="M.Sc.">M.Sc.</option>
<option value="MSW">MSW</option>
<option value="M.Phil.">M.Phil.</option>
<option value="Other Master Degree in Arts / Science / Commerce">Other Master Degree in Arts / Science / Commerce</option>

<option value="BBA">BBA</option>
<option value="BFM (Financial Management)">BFM (Financial Management)</option>
<option value="BHM (Hotel Management)">BHM (Hotel Management)</option>
<option value="Other Bachelor Degree in Management">Other Bachelor Degree in Management</option>
<option value="BHA / BHM (Hospital Administration)">BHA / BHM (Hospital Administration)</option>

<option value="MBA">MBA</option>
<option value="MFM (Financial Management)">MFM (Financial Management)</option>
<option value="MHM  (Hotel Management)">MHM  (Hotel Management)</option>
<option value="MHRM (Human Resource Management)">MHRM (Human Resource Management)</option>
<option value="PGDM">PGDM</option>
<option value="Other Master Degree in Management">Other Master Degree in Management</option>
<option value="MHA / MHM (Hospital Administration)">MHA / MHM (Hospital Administration)</option>

<option value="B.A.M.S.">B.A.M.S.</option>
<option value="BDS">BDS</option>
<option value="BHMS">BHMS</option>
<option value="BSMS">BSMS</option>
<option value="BUMS">BUMS</option>
<option value="BVSc">BVSc</option>
<option value="MBBS">MBBS</option>

<option value="MDS">MDS</option>
<option value="MD / MS (Medical)">MD / MS (Medical)</option>
<option value="MVSc">MVSc</option>
<option value="MCh">MCh</option>
<option value="DNB">DNB</option>

<option value="BPharm">BPharm</option>
<option value="BPT">BPT</option>
<option value="B.Sc. Nursing">B.Sc. Nursing</option>
<option value="Other Bachelor Degree in Medicine">Other Bachelor Degree in Medicine</option>

<option value="M.Pharm">M.Pharm</option>
<option value="MPT">MPT</option>
<option value="Other Master Degree in Medicine">Other Master Degree in Medicine</option>

<option value="BGL">BGL</option>
<option value="B.L.">B.L.</option>
<option value="LL.B">LL.B.</option>
<option value="Other Bachelor Degree in Legal">Other Bachelor Degree in Legal</option>

<option value="LL.M.">LL.M.</option>
<option value="M.L.">M.L.</option>
<option value="Other Master Degree in  Legal">Other Master Degree in  Legal</option>

<option value="CA">CA</option>
<option value="CFA (Chartered Financial Analyst)">CFA (Chartered Financial Analyst)</option>
<option value="CS">CS</option>
<option value="ICWA">ICWA</option>
<option value="Other Degree in Finance">Other Degree in Finance</option>

<option value="IAS">IAS</option>
<option value="IES">IES</option>
<option value="IFS">IFS</option>
<option value="IRS">IRS</option>
<option value="IPS">IPS</option>
<option value="Other Degree in Service">Other Degree in Service</option>

<option value="Ph.D.">Ph.D.</option>
<option value="DM">DM</option>
<option value="Postdoctoral fellow">Postdoctoral fellow</option>
<option value="Fellow of National Board (FNB)">Fellow of National Board (FNB)</option>

<option value="Diploma">Diploma</option>
<option value="Polytechnic">Polytechnic</option>
<option value="Trade School">Trade School</option>
<option value="Others in Diploma">Others in Diploma</option>

<option value="Higher Secondary School / High School">Higher Secondary School / High School</option>

                {/* <label className="flabel">Education</label>
                <input type="text" placeholder="Enter Education" name="education"  className="halfinp" onChange={hanChan} /> */}
                </NativeSelect>
                {/* {statel.acc.education == '' &&<FormHelperText>{err}</FormHelperText>} */}
            </FormControl>
              </div>
            </div>
            <div className="disp">
              <div className="f1">
              <FormControl fullWidth>
          <InputLabel variant="standard" htmlFor="uncontrolled-native">
          Job
        </InputLabel>
          <NativeSelect placeholder="Enter Job" name="job"  className="halfinp" onChange={hanChan} >
                
<option value="0"> - Select Occupation - </option>
<option className="freclas occusele" disabled="">FREQUENTLY USED</option>
<option className="freclas" value=">Business Owner / Entrepreneur">Business Owner / Entrepreneur</option>
<option className="freclas" value="Executive">Executive</option>
<option className="freclas" value="Software Professional">Software Professional</option>
<option className="freclas" value="Manager">Manager</option>
<option className="freclas" value="Supervisor">Supervisor</option>
<option className="freclas" value="Officer">Officer</option>
<option className="freclas" value="Engineer - Non IT">Engineer - Non IT</option>
<option className="freclas" value="Technician">Technician</option>
<option className="freclas" value="Clerk">Clerk</option>
<option className="freclas" value="Marketing Professional">Marketing Professional</option>
<option className="occusele" disabled="">MORE</option>

<option value="Manager">Manager</option>
<option value="Supervisor">Supervisor</option>
<option value="Officer">Officer</option>
<option value="Administrative Professional">Administrative Professional</option>
<option value="Executive">Executive</option>
<option value="Clerk">Clerk</option>
<option value="Human Resources Professional">Human Resources Professional</option>
<option value="Secretary / Front Office">Secretary / Front Office</option>


<option value="Farming Professional">Agriculture &amp; Farming Professional</option>
<option value="Horticulturist">Horticulturist</option>

<option value="Pilot">Pilot</option>
<option value="Air Hostess / Flight Attendant">Air Hostess / Flight Attendant</option>
<option value="Airline Professional">Airline Professional</option>

<option value="Architect">Architect</option>
<option value="Interior Designer">Interior Designer</option>

<option value="Chartered Accountant">Chartered Accountant</option>
<option value="Company Secretary">Company Secretary</option>
<option value="Accounts / Finance Professional">Accounts / Finance Professional</option>
<option value="Banking Professional">Banking Professional</option>
<option value="Auditor">Auditor</option><option value="Financial Accountant">Financial Accountant</option>
<option value="Financial Analyst / Planning">Financial Analyst / Planning</option>
<option value="Investment">Investment Professional</option>

<option value="Fashion Designer">Fashion Designer</option>
<option value="Beautician">Beautician</option>
<option value="Hair Stylist">Hair Stylist</option>
<option value="Jewellery Designer">Jewellery Designer</option>
<option value="Designer (Others)">Designer (Others)</option>
<option value="Makeup Artist">Makeup Artist</option>

<option value="BPO / KPO / ITES Professional">BPO / KPO / ITES Professional</option>
<option value="Customer Service Professional">Customer Service Professional</option>

<option value="Civil Services (IAS / IPS / IRS / IES / IFS)">Civil Services (IAS / IPS / IRS / IES / IFS)</option>

<option value="Analyst">Analyst</option>
<option value="Consultant">Consultant</option>
<option value="Corporate Communication">Corporate Communication</option>
<option value="Corporate Planning">Corporate Planning</option>
<option value="Marketing Professional">Marketing Professional</option>
<option value="Operations Management">Operations Management</option>
<option value="Sales Professional">Sales Professional</option>
<option value="Senior Manager / Manager">Senior Manager / Manager</option>
<option value="Subject Matter Expert">Subject Matter Expert</option>
<option value="Business Development Professional">Business Development Professional</option>
<option value="Content Writer">Content Writer</option>

<option value="Army">Army</option>
<option value="Navy">Navy</option>
<option value="Defence Services (Others)">Defence Services (Others)</option>
<option value="Air Force">Air Force</option>
<option value="Paramilitary">Paramilitary</option>

<option value="5">Teaching / Academician</option>
<option value="Teaching / Academician">Teaching / Academician</option>
<option value="Education Professional">Education Professional</option>
<option value="Training Professional">Training Professional</option>
<option value="Research Assistant">Research Assistant</option>
<option value="Research Scholar">Research Scholar</option>

<option value="Civil Engineer">Civil Engineer</option>
<option value="Electronics / Telecom Engineer">Electronics / Telecom Engineer</option>
<option value="Mechanical / Production Engineer">Mechanical / Production Engineer</option>
<option value="Quality Assurance Engineer - Non IT">Quality Assurance Engineer - Non IT</option>
<option value="Engineer - Non IT">Engineer - Non IT</option>
<option value="Designer">Designer</option>
<option value="Product Manager - Non IT">Product Manager - Non IT</option>
<option value=">Project Manager - Non IT">Project Manager - Non IT</option>

<option value="Hotel / Hospitality Professional">Hotel / Hospitality Professional</option>
<option value="Restaurant / Catering Professional">Restaurant / Catering Professional</option>
<option value="Chef / Cook">Chef / Cook</option>

<option value="Software Professional">Software Professional</option><option value="2">Hardware Professional</option>
<option value="Product Manager">Product Manager</option>
<option value="Project Manager">Project Manager</option>
<option value="Program Manager">Program Manager</option>
<option value="Animator">Animator</option>
<option value="120">UI / UX Designer</option>
<option value="UI / UX Designer">UI / UX Designer</option>
<option value="Web / Graphic Designer">Web / Graphic Designer</option>
<option value="Software Consultant">Software Consultant</option>
<option value="Data Analyst">Data Analyst</option>
<option value="Data Scientist">Data Scientist</option>
<option value="Network Engineer">Network Engineer</option>
<option value="Quality Assurance Engineer">Quality Assurance Engineer</option>

<option value="Lawyer - Legal Professional">Lawyer &amp; Legal Professional</option>
<option value="Legal Assistant">Legal Assistant</option>

<option value="Law Enforcement Officer">Law Enforcement Officer</option>
<option value="Police">Police</option>

<option value="Healthcare Professional">Healthcare Professional</option>
<option value="Paramedical Professional">Paramedical Professional</option>
<option value="Nurse">Nurse</option>
<option value="Pharmacist">Pharmacist</option>
<option value="Physiotherapist">Physiotherapist</option>
<option value="Psychologist">Psychologist</option>
<option value="Therapist">Therapist</option>
<option value="Medical Transcriptionist">Medical Transcriptionist</option>
<option value="Dietician / Nutritionist">Dietician / Nutritionist</option>
<option value="Lab Technician">Lab Technician</option>
<option value="Medical Representative">Medical Representative</option>

<option value="Journalist">Journalist</option>
<option value="Media Professional">Media Professional</option>
<option value="Entertainment Professional">Entertainment Professional</option>
<option value="Event Management Professional">Event Management Professional</option>
<option value="Advertising / PR Professional">Advertising / PR Professional</option>
<option value="Designer">Designer</option>
<option value="Actor / Model">Actor / Model</option>
<option value="Artist">Artist</option>

<option value="Mariner / Merchant Navy">Mariner / Merchant Navy</option>
<option value="Sailor">Sailor</option>

<option value="Scientist / Researcher">Scientist / Researcher</option>

<option value="CXO / President, Director, Chairman">CXO / President, Director, Chairman</option>
<option value="VP / AVP / GM / DGM / AGM">VP / AVP / GM / DGM / AGM</option>

<option value="Technician">Technician</option>
<option value="Arts - Craftsman">Arts &amp; Craftsman</option>
<option value="Student">Student</option>
<option value="Librarian">Librarian</option>
<option value="Business Owner / Entrepreneur">Business Owner / Entrepreneur</option>
<option value="Retired">Retired</option>
<option value="Transportation / Logistics Professional">Transportation / Logistics Professional</option>
<option value="Agent / Broker / Trader">Agent / Broker / Trader</option>
<option value="Contractor">Contractor</option>
<option value="Fitness Professional">Fitness Professional</option>
<option value="Security Professional">Security Professional</option>
<option value="Social Worker /  Volunteer / NGO">Social Worker /  Volunteer / NGO</option>
<option value="Sportsperson">Sportsperson</option>
<option value="Travel Professional">Travel Professional</option>
<option value="Singer">Singer</option>
<option value="Writer">Writer</option>
<option value="Politician">Politician</option>
<option value="Associate">Associate</option>
<option value="Builder">Builder</option>
<option value="Chemist">Chemist</option>
<option value="CNC Operator">CNC Operator</option>
<option value="Distributor">Distributor</option>
<option value="Driver">Driver</option>
<option value="Freelancer">Freelancer</option>
<option value="Mechanic">Mechanic</option>
<option value="Musician">Musician</option>
<option value="Photo / Videographer">Photo / Videographer</option>
<option value="Surveyor">Surveyor</option>
<option value="Tailor">Tailor</option>
<option value="Others">Others</option>

<option value="Doctor">Doctor</option>
<option value="Dentist">Dentist</option>
<option value="Surgeon">Surgeon</option>
<option value="Veterinary Doctor">Veterinary Doctor</option>	


<option value="Not Working">Not Working</option>	

                </NativeSelect>
                {/* {statel.acc.job == '' &&<FormHelperText>{err}</FormHelperText>} */}
            </FormControl>
              </div>
              <div className="f1">
              {/* <FormControl error sx={{ m: 1, width: '100%' }} variant="standard"  >
           <InputLabel htmlFor="standard-adornment-father">Martial Status</InputLabel>
          <Input id="standard-adornment-father"
          type="text" placeholder="Enter MartialStatus" name="martialStatus"  className="halfinp" onChange={hanChan} />
          <FormHelperText>{err}</FormHelperText>
          </FormControl> */}
          <FormControl fullWidth>
          <InputLabel variant="standard" htmlFor="uncontrolled-native">
          Martial Status
        </InputLabel>
          <NativeSelect
            name="martialStatus"
            id="martialStatus"
            onChange={hanChan}
          >
<option value="unmarried">unmarried</option>
<option value="Widows">Widows</option>
<option value="divorce">divorce</option>
<option value="Await divorce">Await divorce</option>
          </NativeSelect></FormControl>
              
              </div>
            </div>
            <div className="disp">
              <div className="f1">
              <FormControl error sx={{ m: 1, width: '100%' }} variant="standard"  >
           <InputLabel htmlFor="standard-adornment-father">siblings</InputLabel>
          <Input id="standard-adornment-father"
          type="text" placeholder="Enter siblings" name="siblings"  className="halfinp" onChange={hanChan} />
          {/* <FormHelperText>{err}</FormHelperText> */}
          </FormControl>
              
              </div>
              <div className="f1">
              <FormControl error sx={{ m: 1, width: '100%' }} variant="standard"  >
           <InputLabel htmlFor="standard-adornment-father">Home Town</InputLabel>
          <Input id="standard-adornment-father"
          type="text"  placeholder="Enter Home Town" name="hometown"  className="halfinp" onChange={hanChan} />
          {/* <FormHelperText>{err}</FormHelperText> */}
          </FormControl>
           
              </div>
            </div>
            <div className="disp">
              <div className="f1">
              <FormControl error sx={{ m: 1, width: '100%' }} variant="standard"  >
           <InputLabel htmlFor="standard-adornment-About">About</InputLabel>
          <Input id="standard-adornment-About"
          type="text"  placeholder="Enter About" name="About"  className="halfinp" onChange={hanChan} />
          {/* <FormHelperText>{err}</FormHelperText> */}
          </FormControl>
             
              </div>
              <div className="f1">
              <FormControl error sx={{ m: 1, width: '100%' }} variant="standard"  >
           <InputLabel htmlFor="standard-adornment-expertation">Expertation</InputLabel>
          <Input id="standard-adornment-expertation"
          type="text"   placeholder="Enter Expertation" name="expertation"  className="halfinp" onChange={hanChan} />
          {/* <FormHelperText>{err}</FormHelperText> */}
          </FormControl>
              
              </div>
            </div>
            <button className='matbut' onClick={hanfsubmit} >Submit</button>
        </div>
        
      </div>
      <h4 className="footer tgg">
        {"  "}
        Copyright © 2023
        Tamizh Manamaalai{"   "}
        All rights reserved.
        {"   "}
        <br />{""}
         Design By{" "}

        <a href="http://skyhost.co.in/" className="link">
         
        Skyhost Technologies.
        </a>{" "}
      </h4>
    </div>
  );
}
