import React from 'react';
import { Close } from '@mui/icons-material';
import DoneIcon from '@mui/icons-material/Done';
import axios from 'axios';


export default function SwitchButton({rec}) {

  const requestpro=async(m)=>{
    var f= localStorage.getItem(process.env.REACT_APP_USERACC);
    var dd = {
        user_id:f,
        requested_id:m
    }
    await axios.post(process.env.REACT_APP_API+'request',dd)
  
        }
        
        const declinepro=async(m)=>{
          
            var f= localStorage.getItem(process.env.REACT_APP_USERACC);
            var dd = {
                user_id:f,
                requested_id:m
            }
            await axios.post(process.env.REACT_APP_API+'decline',dd)
        
                }
                switch(rec.status) {
                  case 'pending':
                      return (
                        <>
                          <div className='d-flex justify-content-center'>
                              <button className="btn btn-success mx-2" aria-label="success">
                              <DoneIcon onClick={()=>requestpro(rec.user_id)} />
                              {rec.status}
                              </button>
                              <button className="btn btn-danger" aria-label="success">
                              <Close onClick={()=>declinepro(rec.user_id)} />
                              decline
                              </button>
                          </div>
                        </>
                      );
                  case "declined":
                    return (
                      <>
                        <div className='d-flex justify-content-center'>
                            <button className="btn btn-success mx-2" aria-label="success">
                            <DoneIcon onClick={()=>requestpro(rec.user_id)} />
                            {rec.status}
                            </button>
                            <button className="btn btn-danger" aria-label="success">
                            <Close onClick={()=>declinepro(rec.user_id)} />
                            decline
                            </button>
                        </div>
                      </>
                    );
                  case "you_pending":
                      return (
                        <>
                          <div className='d-flex justify-content-center'>
                              <button className="btn btn-success mx-2" aria-label="success">
                              <DoneIcon onClick={()=>requestpro(rec.user_id)} />
                              {rec.status}
                              </button>
                              <button className="btn btn-danger" aria-label="success">
                              <Close onClick={()=>declinepro(rec.user_id)} />
                              decline
                              </button>
                          </div>
                        </>
                      );
                      case "accepted":
                      return (
                        <>
                          <div className='d-flex justify-content-center'>
                              <button className="btn btn-success mx-2" aria-label="success">
                              <DoneIcon onClick={()=>requestpro(rec.user_id)} />
                              {rec.status}
                              </button>
                              <button className="btn btn-danger" aria-label="success">
                              <Close onClick={()=>declinepro(rec.user_id)} />
                              decline
                              </button>
                          </div>
                        </>
                      );
                        case "you_declined":
                          return (
                            <>
                              <div className='d-flex justify-content-center'>
                                  <button className="btn btn-success mx-2" aria-label="success">
                                  <DoneIcon onClick={()=>requestpro(rec.user_id)} />
                                  {rec.status}
                                  </button>
                                  <button className="btn btn-danger" aria-label="success">
                                  <Close onClick={()=>declinepro(rec.user_id)} />
                                  decline
                                  </button>
                              </div>
                            </>
                          );
                        case "you_accepted":
                            return (
                              <>
                                <div className='d-flex justify-content-center'>
                                    <button className="btn btn-success mx-2" aria-label="success">
                                    <DoneIcon onClick={()=>requestpro(rec.user_id)} />
                                    {rec.status}
                                    </button>
                                    <button className="btn btn-danger" aria-label="success">
                                    <Close onClick={()=>declinepro(rec.user_id)} />
                                    decline
                                    </button>
                                </div>
                              </>
                            );
                  default:
                    return (
                      <>
                        <div className='d-flex justify-content-center'>
                            <button className="btn btn-success mx-2" aria-label="success">
                            <DoneIcon onClick={()=>requestpro(rec.user_id)} />
                            {rec.status}
                            </button>
                            <button className="btn btn-danger" aria-label="success">
                            <Close onClick={()=>declinepro(rec.user_id)} />
                            decline
                            </button>
                        </div>
                      </>
                    );
                }
 
}
