import React from 'react'
import '../assets/css/bootstrap.css'


export default function Select1({ id,MenuItem,value,arr,label,onChange,name}) {

    return (
        <div className='mb-3 floating-label'>
<select className="form-select" id={id} value={value} onChange={onChange} name={name} aria-label="Default select example">
{arr.map((item,index)=><option key={index} value={item.value} >{item.label}</option>)}
</select>
<label for={id}>{label}</label>
</div>

)
}