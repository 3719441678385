import React, { useEffect, useState } from 'react'
import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import logo from "../assets/logo.png";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Free from '../Admin/free';
import Paid from '../Admin/paid';
import Closed from '../Admin/closed';
import Reject from '../Admin/reject';
import Edit from './Edit';
import axios from 'axios';


export default function Dashboard() {
  const navigate = useNavigate();
  const [data , setdata]=useState('');
  const [data1 , setdata1]=useState('');
  const [data2 , setdata2]=useState(0);
  var count = 0;
  const refresh = () => {
    if (localStorage.getItem(process.env.REACT_APP_USERACC)=== null) {
      navigate("/login"); 
    }
    axios.get(process.env.REACT_APP_API+'alluser')
    .then(response=>{
  // console.log(response.data.data);
  var datal = response.data.data
  const newdata = datal.filter(
    row=>{
        return row.Status === 'active'; 
    }
  ) 
  setdata(newdata);
  const newdata1 = datal.filter(
    row=>{
        return row.Status === 'delete'; 
    }
  ) 
  setdata1(newdata1);
    });
    
  }
  useEffect(()=>{
    
    refresh();  
  }, [])
  

  const hanlogout =()=>{

    localStorage.clear();

    navigate("/login");

};
const hancclick =(e)=>{
  count++;
  axios.get(process.env.REACT_APP_API+'Statusup/'+e.target.name+'/'+e.target.value)
  .then(res=>{
    if (res.data.msg === 'success') {
      refresh();  
    }
  });

    };

  return (
    <div>
       <nav>
            <div className='nav togglemenu'>
            <div className='d-flex sk_gp-5 ' >
            <img src={logo} alt="" className="logo" />
            
            <Link to="" className='togglemenu' >Free Accounts</Link>
            <Link to="paid" className='togglemenu' >Closed</Link>
            {/* <Link to="closed" className='togglemenu'>Closed</Link> */}
            {/* <Link to="reject" className='togglemenu'>Reject</Link> */}
            <button className='matbut togglemenu'  onClick={hanlogout}><ExitToAppIcon /> Logout</button>
            </div>
            </div>
        </nav>	
        <Routes>
          <Route  path='/' element={<Free  data={data}  hancclick={hancclick} />} />
          <Route  path='paid' element={<Paid  data={data1} hancclick={hancclick} />} />
          <Route  path='closed' element={<Closed />} />
          <Route  path='reject' element={<Reject />} />
         <Route
          path="edit/:usid"
          element={
           <Edit  /> 
          }/>
             <Route
          path=":a/edit/:usid"
          element={
           <Edit  /> 
          }/>
        </Routes>


    </div>
  )
}
