import React, { Component, useState } from 'react';
import axios from 'axios';

const Helpline = () => {


//-----------------------Style Sheet---------------------------
    const styles = `
   
    .bigfont {
      
      text-align: center;
    }

    .box1{

        margin: 3%;
        margin-top: 6%;
        border: 1px solid #ec7608;
        border-radius:9px;
        padding: 9%;
        padding-left: 36%;

    }

    .helpcontent{
        font-family: 'Brush Script MT', cursive;
       
  
      }

    .head{
        letter-spacing: 3px; !important ;
        margin-bottom: 6%;
        font-family: Copperplate, Papyrus, fantasy;
        color: red;
        text-shadow: 0 0 3px #e27777, 0 0 5px #42262c;
    }

     
    `;
//-----------------------------------------------------------
    return (
        <div>
        <div className=" p-0  px-6 py-2 bg-theme" >  
        <style>{styles}</style>     
            <div className='h4 text-light' > Helpline</div>
            </div>

            <div className='box1'>
            <div className='head'>
            <h2 >feel free to contact us</h2>
            </div>
        <div className='helpcontent'>
          
            <h3 className='text-muted'>Address:</h3>
            <h3 className='text-muted'>Email: </h3> 
            <h3 className='text-muted'>Contact Number:</h3>             
            
        </div>
        </div> 
        </div>


    );
};

export default Helpline;